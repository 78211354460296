/* eslint-disable import/no-default-export */
export default {
  BackToLogin: 'Retour à la connexion',
  SimpleUsefulPowerful: 'Simple, utile et puissant.',
  MargoWasSurprise: 'Margò réserve une merveilleuse surprise à mon équipe et moi-même.',
  ContactCrif: 'Contacter {{companyName}} pour ouvrir votre compte ou demander des informations.',
  ContactUsText: 'Nous contacter pour ouvrir votre compte ou demander des informations.',
  NotAvailable: 'Pas disponible',
  Name: 'Nom',
  InsertName: 'Saisir votre prénom',
  Surname: 'Nom de famille',
  InsertSurname: 'Saisir votre nom de famille',
  Email: 'E-mail',
  InsertEmail: 'Saisir votre e-mail',
  Message: 'Message',
  InsertMessage: 'Écrire votre texte ici',
  IAgreeWith: "J'accepte",
  PrivacyPolicy: 'la politique de confidentialité',
  Send: 'Envoyer',
  Success: 'Votre message a été envoyé correctement à {{companyName}}.',
  SuccessITA: "Votre message a été envoyé correctement à l'assistance clientèle.",
  ContactCRIF: 'Contacter {{companyName}}',
  ContactUs: 'Nous contacter',
  Company: 'Société',
  InsertCompany: 'Saisir le nom de votre société',
  Country: 'Pays',
  InsertCountry: 'Choisir votre pays',
  JobTitle: 'Intitulé de poste',
  InsertJobTitle: 'Saisir votre intitulé de poste',
  Telephone: 'Numéro de téléphone',
  InsertTelephone: 'Saisir votre numéro de téléphone',
  CompanyName: 'Nom de la société',
  ContactCustomer: 'Contacter le client',
  EnterContactDetails: 'Saisir les coordonnées de contact',
  EnterContactDetailsSubtitle: 'Saisir les données de la personne à contacter',
  ContactName: 'Prénom du contact',
  ContactSurname: 'Nom de famille du contact',
  ContactEmail: 'E-mail de contact',
  ContactTelephone: 'Numéro de téléphone de contact',
  ConfirmOperation: "Confirmer l'opération",
  ConfirmOperationDescription: "L'opération peut prendre quelques minutes. Voulez-vous exécuter l'opération ?",
  CreateOffer: 'Créer offre',
  ContactCustomerOnline: 'Contacter le client en ligne',
  ProceedQuestion: "Voulez-vous exécuter l'opération ?",
  ErrorMessage:
    'Attention : vous ne pouvez pas créer une nouvelle offre pour cette société car une autre est encore en attente. Veuillez la terminer sur la plateforme <1>Apparound</1>.'
}
