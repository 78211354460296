/* eslint-disable import/no-default-export */
export default {
  Title: 'Risultati di ricerca',
  SaletoolTitle: 'Gestione Vendite - Risultati ricerca campagna',
  Search: 'Cerca',
  SearchPlaceholder: 'Ricerca',
  SearchCompanyPlaceholder: "Cerca l'azienda per nome",
  SearchCompaniesAdditionalInfo: 'Nota: per le aziende è possibile usare anche',
  SearchCompaniesAdditionalInfoAutChe:
    'Nota: per le aziende austriache è possibile usare anche Ragione sociale, Partita Iva, Sito web e Company Book number. Per le aziende svizzere è possibile usare anche Partita Iva, Identificativo Azienda, Ragione sociale, Sito web, CH Key',
  NoResults: 'Ci dispiace, non è stato possibile trovare alcun risultato per',
  TryDifferent:
    'Prova a digitare altre parole chiave o utilizza la <0>Ricerca Semantica</0> per cercare termini collegati.',
  TryDifferentWithoutProsp: 'Prova a digitare altre parole chiave.',
  Banner: {
    title: 'Attiva la sezione "Prospezione"!',
    subtitle: 'La ricerca semantica è disponibile solo per gli utenti che hanno acquistato la sezione "Prospezione".',
    gotoAction: 'Contatto {{companyName}}'
  },
  Tabs: {
    Companies: 'Aziende',
    Portfolios: 'Portafogli',
    Campaigns: 'Campagne'
  },
  SearchInformationDialog: {
    Title: 'Cosa può essere cercato?',
    SubTitleWithCampaigns: 'Puoi cercare Aziende, Portafogli o Campagne',
    SubTitle: 'Puoi cercare Aziende o Portafogli',
    CompaniesBy: '<strong>Aziende</strong> usando:',
    PortfoliosCampaignsHelpText: '<strong>Portafogli</strong> e <strong>Campagne</strong> per nome',
    PortfoliosHelpText: '<strong>Portafogli</strong> per nome',
    Companies: {
      CommercialDisplay: 'Insegna',
      Swiss: {
        VatCode: 'Partita Iva',
        IdentificationNumber: 'Identificativo Azienda',
        CompanyName: 'Ragione sociale',
        Website: 'Sito web',
        LegacyCHNumber: 'CH Key',
        CompanyBookNumber: 'Company Book number'
      }
    },
    Countries: {
      ch: 'SVIZZERA',
      at: 'AUSTRIA'
    }
  },
  ShowLocalUnits: 'Mostra Unità Locali',
  HiddenPortfolioCompany:
    "L'impresa ricercata non è accessibile perché potrebbe appartenere ad un portafoglio nascosto da un altro utente della tua organizzazione.",
  Company: 'Impresa',
  Portfolio: 'Portafoglio',
  Campaign: 'Campagna',
  GoTo: {
    Company: 'Report Azienda',
    Portfolio: 'Portafoglio imprese',
    Campaign: 'Gestione vendite'
  }
}
