/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Váš účet',
  Navigation: {
    Account: 'Účet',
    SavedReport: 'Uložený report',
    PortfolioManagement: 'Správa portfólia',
    Targeting: 'Analýza trhu',
    Prospecting: 'Výber klientov',
    Operations: 'Operácie',
    SalesTool: 'Nástroj pre predaj',
    Products: 'Produkty',
    GoalsTasks: 'Ciele a úlohy',
    ReleaseNotes: 'Najnovšie správy',
    Configuration: 'Konfigurácia',
    Appointments: 'Schôdzky'
  },
  Goal: {
    AddGoal: 'Pridať cieľ',
    AddGoalTooltip: 'Vytvorte si cieľ, ktorý si vyberiete v komerčných kampaniach',
    EditGoal: {
      Title: 'Upravte cieľ',
      Body: 'Zadajte reprezentatívny názov cieľa',
      NameInput: 'Názov cieľa',
      Success: 'Cieľ úspešne zmenený.',
      Error: 'Pri úprave cieľa sa vyskytla chyba.'
    },
    DeleteGoal: {
      Title: 'Odstrániť cieľ',
      Subtitle: 'Naozaj chcete odstrániť {{goalName}}?',
      Body: 'Akcia sa vykoná iba v prípade, že cieľ nebol priradený žiadnej aktívnej kampani, inak bude akcia zamietnutá.',
      Success: 'Cieľ úspešne odstránený.',
      Error: 'Pri odstraňovaní cieľa sa vyskytla chyba.',
      ErrorAssignedToActiveCampaign: 'Vybratý cieľ nie je možné vymazať, pretože je priradený k aktívnej kampani.',
      ErrorActiveActionsForSameOrganization:
        'Operáciu nebolo možné dokončiť z dôvodu iných čakajúcich akcií. Skúste to znova o niekoľko minút.'
    },
    EditTask: {
      Title: 'Zmeňte názov úlohy',
      Body: 'Zadajte reprezentatívny názov úlohy',
      NameInput: 'Názov úlohy',
      Success: 'Úloha bola úspešne upravená',
      Error: 'Pri upravovaní úlohy sa vyskytla chyba.'
    },
    DeleteTask: {
      Title: 'Odstrániť úlohu',
      Subtitle: 'Naozaj chcete odstrániť {{taskName}}?',
      Body: 'Akcia sa vykoná iba v prípade, že uvedený cieľ nebol priradený žiadnej aktívnej kampani; inak bude akcia zamietnutá.',
      Success: 'Úloha bola úspešne odstránená',
      Error: 'Pri odstraňovaní úlohy sa vyskytla chyba.',
      ErrorAssignedToActiveCampaign:
        'Vybratú úlohu nie je možné vymazať, pretože v aktívnych kampaniach sú spoločnosti, ktorým bola priradená uvedená úloha. Ak chcete pokračovať v odstraňovaní, odstráňte priradenie v kampani a skúste znova odstrániť úlohu.',
      ErrorActiveActionsForSameOrganization:
        'Operáciu nebolo možné dokončiť z dôvodu iných čakajúcich akcií. Skúste to znova o niekoľko minút.'
    },
    CreateGoal: 'Vytvoriť cieľ',
    NameDesc: 'Zadajte reprezentatívny názov cieľa, ktorý vytvárate.',
    GoalNamePlaceholder: 'Vložte názov cieľa',
    GoalNameLabel: 'Názov cieľa',
    TasksHeader: 'Vyberte si úlohy k dosiahnutiu svojho cieľa',
    TaskDesc:
      'Vložte všetky úlohy potrebné k dosiahnutiu tohto cieľa (min. 3, max. 10). Nižšie čísla označujú nízku mieru dokončenia cieľa.',
    TaskPlaceholder: 'Vložte názov úlohy',
    TaskNumber: 'Číslo úlohy',
    LastTaskDesc: '* Táto úloha sa bude považovať za konečnú úlohu kampane',
    AddTask: 'Pridať úlohu',
    Number: 'Číslo',
    Task: 'Úloha',
    Desc1: 'Číslo blízko úlohy označuje úroveň dokončenia cieľa.',
    Desc2: 'Nižšie čísla označujú nízku mieru dokončenia cieľa.',
    Table: {
      Name: 'Meno',
      NumberOfTasks: 'Počet úloh'
    },
    EmptyText: 'Tu nájdete všetky ciele a úlohy uložené v sekcii „ciele a úlohy“.',
    Visualize: 'Vizualizujte {{goal}}'
  },
  Queries: {
    SearchPlaceholder: 'Find query',
    Table: {
      Name: 'Uložený dopyt',
      CreatedAt: 'Dátum vytvorenia',
      LastUseDate: 'Dátum posledného použitia',
      Monitored: 'Sledované',
      ResumeQuery: 'Obnoviť dopyt',
      Delete: 'Vymazať',
      ResumePortfolioQueryTooltip: 'Otvorte "Správu portfólia" s prednastavenými filtrami z {{queryName}}',
      ResumeProspectingQueryTooltip: 'Otvorte "Výber klientov" s prednastavenými filtrami z {{queryName}}',
      ResumeTargetingQueryTooltip: 'Otvorte "Analýzu trhu" s prednastavenými filtrami z {{queryName}}'
    },
    EmptyProspectingText: 'Tu nájdete všetky dopyty uložené v sekcii "Výber klientov"',
    EmptyTargetingText: 'Tu nájdete všetky dopyty uložené v sekcii "Analýza trhu"',
    DeleteDialog: {
      Title: 'Odstrániť uložený dopyt?',
      Title_0: 'Odstrániť uložený dopyt?',
      Title_1: 'Odstrániť uložené dotazy?',
      Title_2: 'Odstrániť uložené dotazy?',
      Subtitle: 'Potvrďte pokračovanie v operácii',
      Body: 'Chcete vymazať uložený dopyt?',
      Body_0: 'Chcete vymazať uložený dopyt?',
      Body_1: 'Chcete vymazať uložené dotazy?',
      Body_2: 'Chcete vymazať uložené dotazy?',
      Monitored: 'Ak je dopyt monitorovaný, príslušné portfólio sa už nebude aktualizovať',
      Success_0: 'Dopyt bol úspešne vymazaný.',
      Success_1: 'Dotazy boli úspešne vymazané.',
      Success_2: 'Dotazy boli úspešne vymazané.',
      Error_0: 'Pri vymazaní dopytu sa vyskytla chyba.',
      Error_1: 'Pri vymazaní dotazov sa vyskytla chyba.',
      Error_2: 'Pri vymazaní dotazov sa vyskytla chyba.',
      MonitoredQueryError:
        'Niektoré z vybratých dopytov nemožno odstrániť, pretože ide o monitorované dopyty. Ak chcete tieto dopyty vymazať, najskôr vymažte súvisiace monitorované portfóliá.'
    },
    ResumePortfolioQueryDialog: {
      Title: 'Vyberte portfólio, na ktoré sa má dotaz použiť',
      Text: 'Vyberte si jedno z dostupných portfólií.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Vymazať dopyty',
      EmptySelectionText: 'Musíte zvoliť aspoň jeden dopyt',
      Delete: 'Vymazať'
    }
  },
  Products: {
    Table: {
      Name: 'Produkt',
      CreatedAt: 'Dátum vloženia'
    },
    AddProduct: 'Pridať produkt',
    AddProductTooltip: 'Vytvorte produkt, ktorý chcete priradiť v komerčných kampaniach',
    EditProduct: {
      Title: 'Zmeňte názov produktu',
      Body: 'Zadajte reprezentatívny názov produktu',
      NameInputLabel: 'Meno produktu',
      NameInputPlaceholder: 'Meno produktu',
      Success: 'Produkt bol úspešne upravený',
      Error: 'Pri úprave produktu sa vyskytla chyba.'
    },
    DeleteProduct: {
      Title: 'Odstrániť produkt',
      Subtitle: 'Naozaj chcete odstrániť {{productName}}?',
      Body: 'Akcia bude vykonaná iba v prípade, že uvedený produkt nebol priradený k žiadnej aktívnej kampani; inak bude akcia zamietnutá.',
      Success: 'Produkt bol úspešne odstránený',
      Error: 'Pri odstraňovaní produktu sa vyskytla chyba.',
      ErrorAssignedToActiveCampaign:
        'Vybraný produkt nie je možné vymazať, pretože existuje aktívna kampaň/portfólio, ktoré je priradené k uvedenému produktu.',
      ErrorActiveActionsForSameOrganization:
        'Operáciu nebolo možné dokončiť z dôvodu iných čakajúcich akcií. Skúste to znova o niekoľko minút.'
    },
    EmptyText: 'Tu nájdete všetky produkty uložené v sekcii "Produkty"',
    CreateProduct: 'Vytvoriť produkt',
    NameDesc: 'Zadajte reprezentatívny názov produktu, ktorý vytvárate.',
    ProductNamePlaceholder: 'Vložte názov produktu',
    ProductNameLabel: 'Názov produktu'
  },
  PortfolioManagement: {
    EmptyText: 'Tu nájdete všetky dokumenty uložené v sekcii "Správa portfólia"',
    Documents: 'Dokumenty',
    DocumentsTable: {
      Name: 'Dokument',
      CreatedAt: 'Dátum uloženia',
      MimeType: 'Prípona'
    },
    Queries: 'Dotazy',
    DeleteDialog: {
      Body_0: 'Chcete vymazať dokument?',
      Body_1: 'Chcete vymazať dokumenty?',
      Body_2: 'Chcete vymazať dokumenty?',
      Title_0: 'Vymazať dokument?',
      Title_1: 'Vymazať dokumenty?',
      Title_2: 'Vymazať dokumenty?',
      Subtitle: 'Potvrďte pokračovanie v operácii',
      Success_0: 'Dokument bol úspešne vymazaný.',
      Success_1: 'Dokumenty boli úspešne vymazané.',
      Success_2: 'Dokumenty boli úspešne vymazané.',
      Error_0: 'Pri vymazaní dokumentu sa vyskytla chyba.',
      Error_1: 'Pri vymazaní dokumentov sa vyskytla chyba.',
      Error_2: 'Pri vymazaní dokumentov sa vyskytla chyba.'
    },
    Actions: {
      EmptySelectionText: 'Musíte vybrať aspoň jeden dokument',
      EmptyDeleteSelectionTitle: 'Vymazať dokumenty',
      Delete: 'Vymazať',
      Download: 'Stiahnuť',
      ShareTrack: 'Zdieľať stopu'
    },
    CustomTracks: {
      Title: 'Vlastné stopy',
      EditCustomTrack: 'Upraviť vlastnú stopu',
      EditSuccessMessage: 'Vlastná stopa bola úspešne upravená.',
      EditErrorMessage: 'Pri úprave vlastnej stopy sa vyskytla chyba.',
      VisualiseCustomTrack: 'Vizualizujte vlastnú stopu',
      Table: {
        TrackName: 'Názov stopy',
        SaveDate: 'Datum uloženia',
        LastUseDate: 'Dátum posledného použitia',
        OwnerFullName: 'Meno autora',
        ModuleOfOrigin: 'Forma pôvodu'
      },
      DeleteDialog: {
        Body_0: 'Chcete odstrániť vlastnú stopu?',
        Body_1: 'Chcete odstrániť vlastné stopy?',
        Body_2: 'Chcete odstrániť vlastné stopy?',
        Title_0: 'Odstrániť vlastnú stopu',
        Title_1: 'Odstrániť vlastné stopy',
        Title_2: 'Odstrániť vlastné stopy',
        Subtitle: 'Ak chcete pokračovať v operácii, potvrďte.',
        Success_0: 'Vlastná stopa bola úspešne odstránená.',
        Success_1: 'Vlastné stopy boli úspešne odstránené.',
        Success_2: 'Vlastné stopy boli úspešne odstránené.',
        Error_0: 'Pri odstraňovaní vašej vlastnej stopy sa vyskytla chyba.',
        Error_1: 'Pri odstraňovaní vašich vlastných stop sa vyskytla chyba.',
        Error_2: 'Pri odstraňovaní vašich vlastných stop sa vyskytla chyba.'
      },
      RenameDialog: {
        Title: 'Zmeňte názov vlastnej stopy',
        Body: 'Zadajte reprezentatívny názov pre vlastnú stopu',
        NameInput: 'Vlastný názov stopy',
        Success: 'Vlastná stopa bola úspešne premenovaná.',
        Error: 'Pri premenovávaní vlastnej stopy sa vyskytla chyba.'
      },
      EmptyDialog: {
        Title: 'Odstrániť vlastné stopy',
        Text: 'Musíte vybrať aspoň jednu vlastnú stopu.'
      },
      ShareCustomTrackErrorDialog: {
        Title: 'Zdieľanie vlastnej stopy',
        Text: 'Pri zdieľaní vlastnej stopy sa vyskytla chyba.'
      }
    }
  },
  Operations: {
    EmptyText: 'Tu nájdete všetky dopyty uložené v sekcii "Operácie"',
    Table: {
      ResumePortfolioQueryTooltip: 'Otvorte "Operácie" s prednastavenými filtrami z {{queryName}}'
    }
  },
  Info: {
    User: 'Užívateľ',
    LoginAs: 'Prihlásiť sa ako iný užívateľ',
    Level: 'Úroveň',
    Office: 'Pobočka',
    Subscription: 'Predplatné',
    NameAndSurname: 'Meno a priezvisko',
    JobTitle: 'Pracovná pozícia',
    Telephone: 'Telefón',
    CompanyName: 'Názov spoločnosti',
    Country: 'Krajina'
  },
  Account: {
    Password: {
      Password: 'Heslo',
      Current: 'Súčasné',
      ChangePasswordTitle: 'Zmeniť heslo',
      ChangePassword: 'Zmeniť heslo',
      EnterPassword: 'Zadať nové heslo ',
      OldPassword: 'Staré heslo',
      NewPassword: 'Nové heslo',
      ConfirmPassword: 'Potvrdiť heslo'
    },
    Language: {
      Current: 'Súčasný',
      ChangeLanguageTitle: 'Zmeniť jazyk',
      ChangeLanguage: 'Zmeniť jazyk obsahu'
    },
    Currency: {
      Title: 'Mena',
      Current: 'Súčasná',
      ChangeCurrencyTitle: 'Zmeniť menu',
      ChangeCurrency: 'Zmeniť menu obsahu'
    },
    Subscription: {
      Title: 'Moje predplatné (jednotky)',
      Available: 'Dostupné',
      AvailableTooltip: 'Zvyšný kredit (jednotky) z predplatného dostupného pre váš účet',
      Used: 'Použité',
      UsedTooltip: 'Kredit (jednotky) spotrebovaný na vašom účte',
      Total: 'Celkovo',
      TotalTooltip: 'Kredit (jednotky) zakúpený na váš účet',
      Validity: 'Platnosť',
      ValidityInfo: 'Od {{from}} do {{to}}',
      CreditInfoWithoutCompanyName: ' o aktualizáciu predplatného alebo si zakúpte ďalší kredit',
      Error: 'Limity spotreby sa nepodarilo načítať.'
    },
    Contact: {
      Title: 'Kontakty',
      User: 'Užívateľ',
      Email: 'Email',
      UpdateInfoWithoutCompanyName: ' o zmenu vašich kontaktov'
    }
  },
  TargetingTab: {
    SavedQuery: 'Uložený dopyt',
    DownloadedDocuments: 'Stiahnuté dokumenty'
  },
  QueryDetail: {
    VisualiseQuery: 'Vizualizovať dopyt',
    Monitored: 'Sledované'
  },
  ReleaseNotes: {
    Title: 'Najnovšie správy',
    NewNote: 'Nová poznámka k vydaniu',
    NoteTitle: 'Titul',
    NoteDescription: 'Stručný popis',
    NoteContent: 'Obsah',
    WhatsNew: 'Čo je nové',
    GoToNewsPage: 'Prejdite na stránku s novinkami a uvidíte, čo je nové tento mesiac'
  },
  Configuration: {
    ConfigureMargoTitle: 'Konfigurovať Margo',
    CreateOfficeConfiguration: 'Vytvorte konfiguráciu kancelárie',
    CreateUserConfiguration: 'Vytvorte používateľskú konfiguráciu',
    SearchOfficePlaceholder: 'Vyhľadajte názov kancelárie',
    SearchUserPlaceholder: 'Vyhľadajte meno používateľa',
    EmptyText: 'Tu nájdete uloženú konfiguráciu.',
    ViewConfigurationTitle: 'Konfigurácia pre',
    AddConfiguration: {
      OfficeTitle: 'Vyberte jednu alebo viac kancelárií',
      OfficeDescription:
        'Vyberte jednu alebo viac kancelárií, pre ktoré chcete vytvoriť konfiguráciu.\nVytvárate konfiguráciu kancelárie, ktorá sa aplikuje na všetkých súčasných a budúcich používateľov. Konfiguráciu môžete kedykoľvek zmeniť.',
      UsersTitle: 'Vyberte jedného alebo viacerých používateľov',
      UsersDescription:
        'Vyberte jedného alebo viacerých používateľov, pre ktorých chcete vytvoriť konfiguráciu.\nAk používateľ patrí do nakonfigurovanej kancelárie, konfigurácia používateľa prepíše tú kancelársku.',
      ParametersTitle: 'Vyberte parametre',
      ParametersDescription:
        'Vyberte parametre, podľa ktorých budú spoločnosti filtrované.\nAk ste vybrali používateľa, ktorý patrí do nakonfigurovanej kancelárie, nižšie vidíte zoznam filtrov aplikovaných pre túto kanceláriu.\nKonfiguráciu kancelárie môžete prepísať len pre vybraných používateľov, upraviť alebo odstrániť všetky filtre.',
      FullMargoLabel: 'Vypnite všetky filtre a zobrazte celú Margo',
      ConfigurationSuccessful: 'Konfigurácia bola úspešne použitá',
      ConfigurationSuccessfulDescription: 'Konfiguráciu môžete kedykoľvek zmeniť vo svojom účte'
    },
    DeleteConfiguration: {
      Title: 'Vymazať konfiguráciu',
      Subtitle: 'Naozaj chcete vymazať konfiguráciu?',
      Body: 'Viditeľnosť používateľa už nebude obmedzená na parametre zvolené počas konfigurácie.',
      Success: 'Konfigurácia bola odstránená',
      Error: 'There was error while deleting configuration.'
    },
    Table: {
      UserName: 'Používateľské meno',
      OfficeName: 'Názov kancelárie',
      Creator: 'Tvorca konfigurácie',
      UpdatedAt: 'Dátum poslednej aktualizácie'
    },
    Offices: 'úradov',
    Users: 'Používateľov',
    AppliedFilterTabTitle: 'Použité filtre',
    UserTabTitle: 'Používateľ',
    OfficeTabTitle: 'Kancelária',
    AlreadyConfigured: 'Už je nakonfigurovaný',
    FullMargoLabelForView: 'Nie je použitý žiadny filter: používateľ uvidí celé Margo'
  }
}
