import { PolicyDocument } from '../../types'
import { DeepPartial, Dictionary } from '../../utils/types'

export { PolicyDocument }

export enum AppModule {
  PortfolioManagement = 'PortfolioManagement',
  PortfolioManagementCompanyEdit = 'PortfolioManagementCompanyEdit',
  Targeting = 'Targeting',
  Prospecting = 'Prospecting',
  SalesTool = 'SalesTool',
  API = 'API',
  Operations = 'Operations',
  PNRR = 'PNRR',
  Appointment = 'Appointment'
}

export type CanUseAppModules = Record<AppModule, boolean>
export type AvailablePolicyDocuments = Record<PolicyDocument, boolean>

export type LanguageName = {
  short: string
  full: string
}

export type ConfigurationLimits = {
  numberOfNotificationInDrawer: number
  maxNotesNumber: number
  newsPageSize: number
  documentsInPortfolioManagement: number
  latestDocumentWidgetPageSize: number
  campaignVisibleWaterfallChartsPageSize: number
  goalsPageSize: number
  productsPageSize: number
  queryListPageSize: number
  portfoliosPageSize: number
  prospectingCompaniesPageSize: number
  localUnitsPageSize: number
  campaignsPageSize: number
  campaignCompaniesPageSize: number
  numberOfWidgets: number
  notificationsLoadPageSize: number
  configurationPageSize: number
}

export type ConfigurationChartAndData = {
  salesChartColors: string[]
  showMacroAnalysis: boolean
}

export type ConfigurationAuthorization = {
  resetPasswordContactEmail: string
  resetPasswordContactPhone: string
  automaticLogoutTime: number
  timeToCancelAutomaticLogout: number
  hasResetPassword: boolean
}

export type ConfigurationMap = {
  zoom?: number
  center: {
    lat: number
    lng: number
  }
}

export type ConfigurationCompanyStatus = {
  // ^^^ this should be removed and not configured
  id: number
  tKey: string
}

export type ConfigurationFeaturesEnabling = {
  companyPdfReport: boolean
  forgotPassword: boolean
  freeSearchSuggestions: boolean
  localUnits: boolean
  mapView: boolean
  mapZonesBasedOnCountryVisibility: boolean
  mostPopularFilters: boolean
  semanticCluster: boolean
  semanticSearch: boolean
  simplifiedUpload: boolean
  subscriptionSettings: boolean
  synonyms: boolean
  customCharts: boolean
}

export type DefaultInstanceConfig = {
  instanceCode: string
  companyName: string
  availableLanguages: Dictionary<LanguageName>
  languageMap: Dictionary<string>
  defaultLanguage: string
  countryCode: string // <-- DEPRECATED
  decimalPrecision: number
  companyStatus: ConfigurationCompanyStatus[]
  mapFilterMaxRadius: number
  featuresEnabling: ConfigurationFeaturesEnabling
  companySearchFields: string[]
  map: Dictionary<ConfigurationMap>
  auth: ConfigurationAuthorization
  limits: ConfigurationLimits
  salesChartColors: string[]
  allowedModules: AppModule[]
  policyDocuments: PolicyDocument[]
  chartsAndData: ConfigurationChartAndData
  minCountOfSameStatusCompaniesForAnalyze: number
  monitoredPortfolioNotificationTimestampOffsetInMinutes: number
  freemiumReturnUrl: string
  matomo: {
    urlBase: string | undefined
    siteId: number | undefined
  }
}

export type DefaultInstanceConfigDto = DeepPartial<DefaultInstanceConfig>

export type InstanceConfigDto = DeepPartial<DefaultInstanceConfig>

export type SamlAuthConfig = {
  authority: string
  authorizeUrl: string
  tokenUrl: string
  clientIds: Dictionary<string>
}

export type DisabledFilterNode = {
  fieldId: string
  nodeValues: string[]
}

export type ApplicationConfigDto = Partial<{
  url: string
  recaptcha: string
  mapsKey: string
  productFruitsKey: string
  provisionalProductFruitsKey: string
  chatBotTestOrganizationCodes: string[]
  usercentricsSettingsId: string
  default: DefaultInstanceConfigDto
  showReactQueryDevTool: boolean
  samlAuth: SamlAuthConfig
  freemiumReturnUrl: string
  matomo: {
    siteId: number
    freemiumSiteId: number
    urlBase: string
    trackedUrls?: string[]
  }
  disabledFilterNodes: DisabledFilterNode[]
}>

// STATE MACHINE

export enum LoadStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED'
}

// AppConfig (on the beginning)
export type AppConfig = {
  appConfigLoadStatus: LoadStatus
  default?: DefaultInstanceConfig
  recaptcha?: string
  mapsKey?: string
  url?: string
  productFruitsKey?: string
  provisionalProductFruitsKey?: string
  chatBotTestOrganizationCodes?: string[]
  usercentricsSettingsId?: string
  samlAuth?: SamlAuthConfig
  freemiumReturnUrl?: string
  matomo: {
    siteId?: number
    freemiumSiteId?: number
    urlBase?: string
    trackedUrls?: string[]
  }
  disabledFilterNodes?: DisabledFilterNode[]
}

// UserConfig (session + instance config)
export type UserConfig = {
  userConfigLoadStatus: LoadStatus
  instance?: InstanceConfigDto
}

type UserConfigNotLoaded = {
  userConfigLoadStatus: LoadStatus.NOT_LOADED | LoadStatus.LOADING | LoadStatus.FAILED
}

type UserConfigLoaded = {
  userConfigLoadStatus: LoadStatus.LOADED
  instance: InstanceConfigDto
}

export type UserConfigStates = UserConfigNotLoaded | UserConfigLoaded

// Configuration machine

export type ConfigState = {
  isSignalRStarted: boolean
  showReactQueryDevTool: boolean
  appLanguage: string
} & AppConfig &
  UserConfig

export type LocalStorageConfigState = {
  showPortfolioWizard?: false
}
