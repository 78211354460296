import axios, { CancelToken } from 'axios'
import _ from 'lodash'
import { CompoundExpression } from '@api/expressionTypes'
import { GetFilterSuggestionsRequest, GetFilterSuggestionsResponse } from '@api/suggestions/types'
import { CampaignStatistics, AssignedUser, CompanyNote } from '../../features/salestool/types'
import { SingleAssignedUserInfo } from '../../features/salestoolSaleDetail/types'
import { DomainNodesDataRequest, DomainNodesDataResponse, SearchDomainNodesRequest } from '../filters/types'
import { CustomVariablesResponse } from '../types'
import {
  AddPortfolioToCampaignRequest,
  CampaignAssignmentsReq,
  CampaignCompaniesForMap,
  CampaignCompaniesMapReq,
  CampaignCompaniesReq,
  CampaignCompaniesRes,
  CampaignRequestParams,
  CampaignsResponse,
  UpdateCampaignRequest,
  CampaignValidationParameters,
  CampaignValidationResult,
  DuplicateCompanyReq,
  MapDistributionRes,
  NewCampaign,
  NewCampaignResult,
  AssignedUserListType,
  TasksAssignmentReq,
  UpdateCampaignByPortfolioRequest,
  GetCampaignResponse,
  SelectListResponse,
  UpdateResponseDto,
  ArchiveCampaignRequest,
  RenewCampaignRequest,
  ExportCampaignRequest,
  CampaignsStatusCountResponse,
  BestAssignedUsersByTask,
  GetBranchesWithUsersResponse
} from './types'

export const getCompanies = (campaignId: string, data: CampaignCompaniesReq) =>
  axios
    .post<CampaignCompaniesRes>(`/api/SalesTool/campaign/${campaignId}/campaign-companies`, data, {
      allowedErrorStatuses: [400]
    })
    .then(resp => {
      // eslint-disable-next-line no-param-reassign
      resp.data.data = _.map(resp.data.data, c => ({
        ...c,
        cells: _.map(resp.data.configuration.fields, configField => {
          const index = _.findIndex(c.cells, { columnId: configField.jsonPath })
          return c.cells[index]
        })
      }))
      return resp
    })

export const getCampaignStats = (campaignId: string, assignedUserId?: string) =>
  axios
    .get<CampaignStatistics>(`api/SalesTool/campaign/${campaignId}/campaign-stats`, { params: { assignedUserId } })
    .then(resp => resp.data)

export const getProductsList = () =>
  axios
    .get<SelectListResponse>('/api/products', {
      params: {
        sort: [
          {
            property: 'Name',
            order: 'Asc'
          }
        ]
      }
    })
    .then(resp => resp.data)

export const getAssignedUserList = (listType: AssignedUserListType) =>
  axios.get<AssignedUser[]>(`/api/SalesTool/assigned-user/select-list?listType=${listType}`).then(r => r.data)

export const getBranchesWithUsers = () =>
  axios.get<GetBranchesWithUsersResponse>('/api/SalesTool/branches-with-users').then(r => r.data)

export const getAssignedUserInfo = (assignedUserId: string, campaignId?: string) =>
  axios
    .get<SingleAssignedUserInfo>(`api/SalesTool/assigned-user/${assignedUserId}`, { params: { campaignId } })
    .then(resp => resp.data)

export const getCampaign = (campaignId: string) =>
  axios.get<GetCampaignResponse>(`api/SalesTool/campaign/${campaignId}`).then(resp => resp.data)

export const updateCampaign = (campaignId: string, data: UpdateCampaignRequest) =>
  axios.put<UpdateResponseDto>(`/api/SalesTool/campaign/${campaignId}`, data)

export const archiveCampaign = (campaignId: string, data: ArchiveCampaignRequest) =>
  axios.post<UpdateResponseDto>(`/api/SalesTool/${campaignId}/archive`, data)

export const validateCampaign = (campaign: CampaignValidationParameters) =>
  axios.post<CampaignValidationResult>('/api/SalesTool/campaign/validate', campaign, { allowedErrorStatuses: [400] })

export const getCompanyNotes = (companyId: string) =>
  axios
    .get<CompanyNote[]>(`/api/SalesTool/campaigncompany/${companyId}/note`, { allowedErrorStatuses: [400] })
    .then(resp => resp.data)

export const addCompanyNote = (companyId: string, note: string) =>
  axios.post(`/api/SalesTool/campaigncompany/${companyId}/note`, { note }, { allowedErrorStatuses: [400] })

export const deleteCompanyNote = (companyId: string, noteId: string) =>
  axios.delete(`/api/SalesTool/campaigncompany/${companyId}/note/${noteId}`)

export const getCampaigns = (params: CampaignRequestParams) =>
  axios
    .get<CampaignsResponse>('/api/SalesTool/campaign', {
      params
    })
    .then(resp => resp.data)

export const getCampaignsStatusCount = () =>
  axios.get<CampaignsStatusCountResponse>('/api/SalesTool/campaign/count-by-status').then(resp => resp.data)

export const validateDuplicateCompany = (companyId: string, data: DuplicateCompanyReq) =>
  axios.post<boolean>(`/api/SalesTool/campaigncompany/${companyId}/validate-duplicate`, data, {
    allowedErrorStatuses: [400]
  })

export const duplicateCompany = (companyId: string, data: DuplicateCompanyReq) =>
  axios.post<UpdateResponseDto>(`/api/SalesTool/campaigncompany/${companyId}/duplicate`, data, {
    allowedErrorStatuses: [400]
  })

export const deleteCompany = (campaignCompanyId: string) =>
  axios.delete(`/api/SalesTool/campaigncompany/${campaignCompanyId}`)

export const moveCompany = (campaignCompanyId: string, campaignId: string) =>
  axios.put(`/api/SalesTool/campaigncompany/${campaignCompanyId}/move`, { campaignId })

export const saveTaskAssignment = (campaignId: string, companyId: string, data: { taskId: string }) =>
  axios.put(`/api/SalesTool/campaign/${campaignId}/campaign-company/${companyId}`, data, {
    allowedErrorStatuses: [400]
  })

export const getCampaignMapCompanies = (campaignId: string, data: CampaignCompaniesMapReq) =>
  axios.post<CampaignCompaniesForMap>(`/api/SalesTool/campaign/${campaignId}/campaign-company-locations`, data)

export const getBestAssignedUsersByTask = (campaignId: string, taskId: string) =>
  axios
    .post<BestAssignedUsersByTask>(`/api/SalesTool/campaign/${campaignId}/best-assigned-users-by-task?taskId=${taskId}`)
    .then(resp => resp.data)

export const getSalestoolDomainNodesData = (
  data: DomainNodesDataRequest,
  campaignId: string,
  cancelToken?: CancelToken
) => axios.post<DomainNodesDataResponse>(`/api/Salestool/${campaignId}/domain-nodes-data`, data, { cancelToken })

export const getSalestoolSearchDomainNodesDataPath = (requestData: SearchDomainNodesRequest, campaignId: string) =>
  axios.post<DomainNodesDataResponse>(`/api/Salestool/${campaignId}/search-domain-nodes-data`, requestData)

export const getMapDistribution = (campaignId: string, query: CompoundExpression | undefined) =>
  axios.post<MapDistributionRes>(`api/Salestool/${campaignId}/map-distribution`, { query }).then(resp => resp.data)

// Layer actions
export const addCampaign = (campaign: NewCampaign) =>
  axios.post<NewCampaignResult>('/api/SalesToolActions/create-campaign', campaign)

export const renewCampaign = (data: RenewCampaignRequest) =>
  axios.post<CampaignValidationResult>('/api/SalesToolActions/renew-campaign', data)

export const saveCampaignAssignments = (data: CampaignAssignmentsReq) =>
  axios.post('/api/SalesToolActions/assign-user-product', data, {
    allowedErrorStatuses: [400]
  })

export const addPortfolio = (portfolioId: string, campaignId: string) => {
  const requestData: AddPortfolioToCampaignRequest = {
    selection: {
      sourceContainerItemId: portfolioId,
      destinationContainerItemId: campaignId
    }
  }

  return axios.post('/api/SalesToolActions/add-portfolio', requestData, { allowedErrorStatuses: [400] })
}

export const updateCampaignByPortfolio = (portfolioId: string, campaignId: string) => {
  const requestData: UpdateCampaignByPortfolioRequest = {
    selection: {
      sourceContainerItemId: portfolioId,
      destinationContainerItemId: campaignId
    }
  }

  return axios.post('/api/SalesToolActions/update-campaign-by-portfolio', requestData, { allowedErrorStatuses: [400] })
}

export const saveTasksAssignment = (data: TasksAssignmentReq) =>
  axios.post('/api/SalesToolActions/set-companies-task', data, { allowedErrorStatuses: [400] })

export const deleteCampaign = (campaignId: string) => axios.delete(`/api/SalesTool/campaign/${campaignId}`)

export const getExportCampaign = (campaignId: string, data: ExportCampaignRequest) =>
  axios.post(`/api/SalesTool/campaign/${campaignId}/export`, data)

export const getCampaingCustomVariables = (campaingId: string) =>
  axios.get<CustomVariablesResponse>(`/api/SalesTool/${campaingId}/customVariables`).then(res => res.data)

export const getFiltersSuggestions = (campaignId: string, request: GetFilterSuggestionsRequest) =>
  axios
    .post<GetFilterSuggestionsResponse>(`/api/SalesTool/${campaignId}/filters-suggestions`, request)
    .then(x => x.data)
