import { CompoundExpression } from '@api/expressionTypes'
import { Filter, SemanticSearch } from '../../features/filters/types'
import { CodeWithSelector, DomainFilterSelector } from '../../features/filters/types/domainFilter'
import { QueryType } from '../../types'
import { TreeKeySelection } from '../../utils/tree'
import { OrderByDirection, SemanticSearchRequest } from '../types'

export type FilterGroupDto = {
  groupId: string
  filterDefinitions: Array<Filter>
}

export type FilterResponseDto = {
  groupsOfFilters: Array<FilterGroupDto>
}

export enum FilterStructureModuleRequest {
  PortfolioManagement = 'PortfolioManagement',
  Prospecting = 'Prospecting',
  Targeting = 'Targeting',
  SalesTool = 'SalesTool',
  Operations = 'Operations'
}

export type FilterStructure = {
  text: string
  description?: string
  id?: string
  enabled?: boolean
  fieldId?: string
  semanticSearchType?: string
  filterHierarchy?: string
  position?: number
  isCustomVar?: boolean
  children?: FilterStructure[]
  isMostPopularFilter?: boolean
  hasExactSearch?: boolean
  exactSearchTooltip?: string
}

export enum FilterDefinitionRequestType {
  Simple = 'Simple',
  Hierarchy = 'Hierarchy',
  CustomVar = 'CustomVar'
}

export type FilterGroup = {
  id: string
  text: string
  description?: string
  showInfoIcon: boolean
  filters: FilterStructure[]
}

export type FilterStructureResponse = {
  filtersGroups: FilterGroup[]
}

export type FilterStructureRequestDef = {
  id?: string
  definitionType: FilterDefinitionRequestType
}

export type FilterDefinitionRequest = {
  id: string
  definitionType: FilterDefinitionRequestType
  groupId: string
}

export type FilterGroupRequestDto = {
  groupId: string
  filterDefinitions: FilterStructureRequestDef[]
}

export type FilterDefinitionsRequest = {
  campaignId?: string
  portfolioIds?: string[]
  groupsOfFilters: FilterGroupRequestDto[]
  evaluateBounds?: boolean
}

export type KeyTreeResponse = { serializedTree: string }

export type DomainNodesDataBase = {
  filterHierarchy: string
  query: CompoundExpression | undefined
  targetingQuery: CompoundExpression | undefined
  semantic?: SemanticSearchRequest
  ignoredPortfolios: string[]
  selectedPortfolios?: string[]
}

export type SearchDomainNodesRequest = {
  selectors: DomainFilterSelector[]
  domainText: string
  isSingleSelectionEnabled: boolean
  singleSelectionTargetJsonPath?: string
  exactSearch?: boolean
} & DomainNodesDataBase

export type DomainNodesDataRequest = {
  nodes: CodeWithSelector[]
  isSingleSelectionEnabled: boolean
  singleSelectionTargetJsonPath?: string
} & DomainNodesDataBase

export type DomainNodeDataResponse = {
  code: string
  displayName: string
  count: number
  hasChildren: boolean
  parentPath: string[]
}

export type DomainNodesDataResponse = DomainNodeDataResponse[]

export type DomainNodesDataNotEmptyRequest = {
  useSingleSelection: boolean
  includeEmpty: boolean
  portfolioId?: string
  campaignId?: string
  includeZero?: boolean
  ignoredClusterCodes?: string[]
} & DomainNodesDataBase

export type DomainItemsPerLevel = {
  fieldId: string
  domainType: string
  items: DomainNodesDataResponse
}

export type DomainNodeDataNotEmptyResponse = {
  filterHierarchy: string
  perLevel: DomainItemsPerLevel[]
}

export type LocalUnitsFilterInfo = {
  officeTypeForLocalUnits: OfficeTypeForLocalUnits[]
  relatedFiltersKeysForLocalUnits: string[]
  ignoredFiltersKeysForLocalUnits: string[]
}

export type OfficeTypeForLocalUnits = {
  officeTypeFieldId: string
  officeTypeCodeForLocalUnit: string
}

export type QueryOrderByOption = {
  jsonPath: string
  direction: OrderByDirection
}

export type FilterQueryDto = {
  id: string
  // type: DocumentType
  associatedEntityId?: string
  type: QueryType
  queryName?: string
  query: CompoundExpression
  filterGroups: Record<string, TreeKeySelection[]>
  selectedPortfoliosIds?: string[]
  excludedPortfoliosIds?: string[]
  semanticSearch?: SemanticSearch
  orderBy?: QueryOrderByOption[]
}

export type FiltersDefinitionRequest = {
  fieldId: string | undefined
  fieldDefinitionType: FilterDefinitionRequestType | undefined
  portfolioIds?: string[]
  campaignId?: string
  groupId?: string
  enabled?: boolean
}
