/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Retour',
  Continue: 'Continuer',
  Search: 'Rechercher',
  Cancel: 'Annuler',
  Ok: 'Ok',
  Save: 'Enregistrer',
  SaveAndShare: 'Enregistrer et partager',
  Delete: 'Supprimer',
  Move: 'Déplacer',
  Edit: 'Modifier',
  Rename: 'Renommer',
  Add: 'Ajouter',
  Note: 'Note',
  Select: 'Sélectionner',
  Hide: 'Masquer',
  Show: 'Afficher',
  Visualise: 'Visualiser',
  HideDetail: 'Masquer les détails',
  ShowDetail: 'Afficher détails',
  Duplicate: 'Dupliquer',
  Download: 'Télécharger',
  Collapse: 'Réduire',
  Loading: 'Chargement',
  Language: 'Langue',
  // COMPANY STATUS
  Prospect: 'Prospect',
  Lead: 'Principal',
  Client: 'Client',
  Supplier: 'Fournisseur',
  Competitor: 'Concurrent',
  RangeSelected: 'Plage sélectionnée',
  Close: 'Fermer',
  ViewDetails: 'Voir détails',
  SearchResults: {
    ResultsFor: 'Résultats pour',
    SendToSemanticSearch: 'Envoyer à la recherche sémantique',
    SendToSemanticSearchToolTip: 'Rechercher le mot-clé dans la recherche sémantique'
  },
  NumericRange: {
    DataNotAvailable: 'Données non disponibles pour les entreprises concernées.',
    InvalidValue: 'Formule non valable.',
    IntegersOnly: 'Seuls les nombres entiers sont admis.',
    MinGreaterThanMax: 'Valeur minimum supérieure à la valeur maximum.',
    MaxLessThanMin: 'Valeur maximum inférieure à la valeur minimum.',
    MinOutOfrange: 'Valeur minimum hors plage.',
    MaxOutOfrange: 'Valeur maximum hors plage.'
  },
  DateRange: {
    From: 'De',
    To: 'À'
  },
  DomainFilter: {
    SaveAndClose: 'Enregistrer filtres & fermer',
    SeeAll: 'Tous les {{count}} filtres',
    DeleteAll: 'Supprimer tous',
    Multiselection: 'sélection multiple'
  },
  MapFilter: {
    ChoosePoint: 'Choisir un point directement sur la carte',
    Range: 'Plage',
    SelectedCircle: '{{distance}} de {{address}}',
    SelectedPolygon: 'Zone sélectionnée sur la carte',
    Address: 'Adresse',
    CompanyName: 'Nom de la société',
    FindMe: 'Trouver sur la carte',
    SearchFromHere: 'Démarrer la recherche à partir de ce point',
    GeolocationErrorTitle: "Une erreur s'est produite",
    GeolocationError: "Cette option n'est pas disponible actuellement."
  },
  Reset: 'Réinitialiser',
  SelectAll: 'Sélectionner tous',
  DeselectAll: 'Désélectionner tous',
  DoYouWantToContinue: 'Voulez-vous continuer ?',
  NotSwitch: 'Pas',
  Yes: 'Oui',
  No: 'Non',
  DoNotKnow: 'Ne sais pas',
  On: 'Marche',
  Off: 'Arrêt',
  LoadMore: 'Charger plus',
  SeeAll: 'Voir tous',
  HideAll: 'Cacher tout',
  Filters: {
    UnlockFilters:
      'Vous souhaitez affiner votre recherche ? Débloquez les 300+ filtres de Margo en <0>cliquant ici</0>',
    NoFilters: "Aucun filtre n'a été sélectionné !",
    Select: 'Sélectionner filtre',
    Filters: 'Filtres',
    SemanticClustersInfo: 'Essayez le nouveau filtre Margo Semantic Clusters.',
    SemanticClustersDeepActionInfo: 'Affinez votre recherche avec le nouveau filtre Margo Semantic Clusters',
    EditFilters: 'Modifier les filtres pour actualiser la liste',
    StartNewSearch: 'Démarrer nouvelle recherche',
    ResetFilters: 'Réinitialiser tous les filtres',
    UploadList: 'Charger liste',
    UploadListTooltip: 'Saisir une ou plusieurs valeurs séparées par un espace.'
  },
  InsertTheNumber: 'Saisir le numéro',
  OrderBy: 'Classé par',
  BackToLogin: 'Retour à la connexion',
  SimpleUsefulPowerful: 'Simple, utile et puissant.',
  MargoWasSurprise: 'Margò réserve une merveilleuse surprise à mon équipe et moi-même.',
  Targeting: 'Analyser marché',
  Prospecting: 'Trouver des prospects',
  PortfolioManagement: 'Gestion des portefeuilles',
  Actions: 'Actions',
  GroupActions: {
    GroupActions: 'Actions de groupe',
    Actions: 'Actions',
    Select: 'Sélectionner',
    ToggleTooltip:
      "Basculez les actions de groupe pour sélectionner les entreprises souhaitées en appliquant la coche ou effectuez une sélection multiple. Vous pouvez ensuite enregistrer les sociétés sélectionnées à partir du menu Actions. Vous ne pourrez sélectionner aucun appel à l'action.",
    ButtonDisabledByGroupActions:
      "En activant les actions de groupe, vous ne pourrez sélectionner aucun appel à l'action. Pour utiliser ces boutons, désactivez le commutateur Actions de groupe.",
    DeactivateToggleTooltip:
      "Désactivez les actions de groupe pour pouvoir utiliser les boutons d'appel à l'action en haut de la page."
  },
  Confirm: 'Confirmer',
  IsEnriched: 'Enrichi',
  Logout: 'Déconnexion',
  ChangeCountry: 'Changer de pays',
  TrialUser: "Utilisateur d'essai",
  You: 'Vous',
  Legend: 'Légende',
  Enrich: {
    Enrichment: 'Enrichissement',
    RecapDesc:
      "L'enrichissement de la société avec les données de {{companyName}} permet le téléchargement de ces informations et leur actualisation régulière.",
    CompaniesToBeEnriched: 'Société à enrichir',
    CompaniesToBeEnriched_plural: 'Sociétés à enrichir',
    PortfolioInfo1:
      "Cette société n'est pas présente dans la section Gestion du portefeuille. L'ajouter au portefeuille.",
    PortfolioInfo2: "En enregistrant les données de {{companyName}}, la société s'est déjà enrichie.",
    SelectPortfolio: 'Sélectionner portefeuille',
    UploadedPortfolio: 'Portefeuille chargé',
    CreateNewPortfolio: 'Créer nouveau portefeuille',
    PortfolioName: 'Nom du portefeuille',
    EnrichmentComplete: 'Enrichissement terminé'
  },
  ConfigGuardErrorPage: {
    Reload: 'Recharger',
    Subtitle: "Impossible de charger la configuration d'application.",
    Title: 'Nous sommes désolés'
  },
  Uploaded: 'Chargé',
  Date: 'Date',
  Page: 'Page',
  Validating: 'En cours de validation',
  AutoLogout: {
    Title: 'Déconnexion automatique',
    Text: 'En raison de votre inactivité, vous serez automatiquement déconnecté dans {{count}} secondes',
    Text_plural: 'En raison de votre inactivité, vous serez automatiquement déconnecté dans {{count}} secondes'
  },
  ForceLogout: {
    Title: 'Utilisateur déconnecté',
    Desc: "Votre utilisateur a été déconnecté parce que la session s'est terminée ou qu'une nouvelle session a été lancée via un autre navigateur ou un autre appareil."
  },
  ImprintPolicy: 'Mentions légales',
  PrivacyPolicy: 'Politique de confidentialité',
  CookiesPolicy: 'Politique en matière de cookies',
  TermsOfUse: "Conditions d'utilisation",
  SelectDate: 'Sélectionner date',
  queries: {
    loadQuery: {
      dialogTitle: 'Charger une recherche enregistrée',
      OkBtn: 'Charger',
      title: 'Sélectionner recherche enregistrée',
      description:
        "Choisir l'analyse à poursuivre, il est possible de reprendre l'analyse à partir de celles enregistrées",
      selectTitle: 'Requête enregistrée'
    }
  },
  SaveList: {
    CreateTagTitle: 'Créer étiquette',
    CreateTagLabel: 'Créer étiquette (option)',
    CreateTagDesc: 'Chaque société est définie par une étiquette à créer',
    InsertTag: 'Insérer une étiquette',
    SaveList: 'Enregistrer liste',
    SaveOrCreate: 'Souhaitez-vous ajouter la liste à un portefeuille ou en créer un nouveau ?',
    SelectPortfolio: 'Sélectionner portefeuille',
    SaveListDesc:
      'La liste sera automatiquement enregistrée sous le statut « Prospect » dans la section « Gestion des portefeuilles »',
    UploadedPortfolio: 'Portefeuille actualisé',
    CreateNew: 'Créer nouveau',
    SelectNumberOfCompanies:
      "Sélectionner le nombre de sociétés à ajouter au portefeuille et les classer selon l'un des critères de la liste.",
    NumberOfCompanies: 'Nombre de sociétés',
    BackToFullList: 'Retour à la liste complète',
    SavingFullList: 'Enregistrement en cours de la liste complète des sociétés',
    OrderByOptions: {
      Turnover: "Chiffre d'affaire",
      TurnoverEur: "Chiffre d'affaire (EUR)",
      TurnoverCzk: "Chiffre d'affaire (CZK)",
      NOfEmployees: "N° d'employés",
      EmployeeRange: 'Plage de salariés',
      Ebit: 'Ebit'
    },
    SendToSalesInfo: "Avant d'envoyer une liste à l'outil de vente, créer un portefeuille à partir de la liste.",
    Enrichment: {
      Enrichment: 'Enrichissement',
      EnrichmentComplete: 'Enrichissement terminé',
      EnrichmentDescription:
        "L'enrichissement de la société avec les données de {{companyName}} permet le téléchargement de ces informations et leur actualisation régulière",
      CompaniesInList: 'Société dans cette liste',
      CompaniesInList_plural: 'Sociétés dans cette liste',
      CompaniesEnriched: 'Société déjà enrichie',
      CompaniesEnriched_plural: 'Sociétés déjà enrichies',
      CompaniesNotEnriched: 'Société pas enrichie',
      CompaniesNotEnriched_plural: 'Sociétés pas enrichies',
      CreditsWillBeUsed: 'Appel utilisé',
      CreditsWillBeUsed_plural: 'Appels utilisés',
      CreditsRemaining: 'Appel restant',
      CreditsRemaining_plural: 'Appels restants'
    },
    SaveListMonitorDesc:
      'En cas de portefeuille surveillé, la requête enregistrée est disponible dans la section « Prospection » de votre compte.',
    Tag: 'Étiquette',
    AllowedCompaniesCountExceeded: 'Une liste ne peut pas contenir plus de 100 000 enterprises',
    DontShowThisMessageAnymore: 'Ne plus afficher ce message'
  },
  ExcludePortfolio: {
    BasedOn: 'Sur la base de',
    SelectedPortfolios: 'Portefeuilles sélectionnés',
    AllCompanyInCrifIta: 'Toutes les sociétés italiennes',
    AllCompanyInCrifDeu: 'Toutes les sociétés allemandes',
    AllCompanyInCrifCzsk: 'Toutes les sociétés disponibles',
    AllCompanyInCrifFareast: 'Toutes les sociétés disponibles',
    AllCompanyInCrifChe: 'Toutes les sociétés suisses',
    AllCompanyInCrifAut: 'Toutes les sociétés autrichiennes',
    AllCompanyInCrifCheAut: 'Toutes les sociétés autrichiennes et suisses',
    ExcludePortfolio: 'Exclure un portefeuille',
    ExcludeCampaign: 'Exclure la campagne',
    ExcludeEnrichedCompanies: 'Exclure les entreprises enrichies',
    SelectCompaniesWithContacts: 'Sélectionnez des entreprises avec des contacts',
    Configuration: 'la configurazione impostata sulla tua utenza',
    AndConfiguration: 'e la configurazione impostata sulla tua utenza'
  },
  User: {
    Role: {
      Supervisor: 'Superviseur',
      AreaManager: 'Responsable de secteur',
      Sales: 'Commercial'
    },
    UserName: 'Nom utilisateur'
  },
  Freemium: {
    NotAvailableFeature: 'Non disponible pour Freemium Margo',
    UnlockFeature:
      '<0>Cliquez ici</0> pour débloquer toutes les fonctionnalités en contactant notre équipe commerciale',
    UnlockSection: 'Contactez notre service client pour acheter Margo et déverrouiller cette section',
    MaximumNumberOfPortfoliosReached:
      "Vous avez atteint le nombre maximal de portefeuilles. Veuillez <0>cliquer ici</0> pour débloquer d'autres portefeuilles ainsi que toutes les autres fonctionnalités en contactant notre équipe commerciale, ou supprimez l'un des portefeuilles existants."
  },
  FeatureNotEnabled: "Cette fonctionnalité n'est pas activée pour le moment. Contactez-nous pour l'activer.",
  ClickToSort: 'Trier par ordre',
  Assignee: 'Bénéficiaire',
  SaveQuery: {
    DialogTitle: 'Enregistrer recherche',
    Description:
      "Saisir le nom de sauvegarde de la recherche ou sélectionner une requête existante pour l'écraser en écriture.",
    InputLabel: 'Nom de requête',
    InputPlaceholder: 'Nom de recherche',
    UpdateExisting: 'Actualiser existante',
    CreateNew: 'Créer nouveau',
    DoNotCreate: 'Ne créez pas',
    AddToDashboardConfirmMessage: 'Voulez-vous ajouter une requête au tableau de bord ?',
    AddToDashboardCheckboxText: 'Afficher dans le tableau de bord',
    MaximumWidgetCountReached: 'Le nombre maximum de cases du tableau de bord est atteint.',
    SavedQuery: 'enregistré correctement.',
    SavedQueryAndDashboard: 'enregistré et ajouté dans le tableau de bord.',
    SavedQueryDescription: 'La requête enregistrée se trouve dans le répertoire relatif à la zone de référence.'
  },
  AssistantAi: {
    ChatTitle: 'Assistant Margò',
    Assistant: 'Assistant',
    Customer: 'Tu',
    Writing: 'Écrivant',
    Feedback: 'Merci pour vos retours !',
    Hello: ' Salut {{userFirstName}}, comment puis-je vous aider?',
    Placeholder: 'Insérez ici votre message',
    DeletionConfirmation: 'Êtes-vous sûr de vouloir supprimer le chat?'
  },
  ShareQuery: {
    Offer: {
      Title: 'Partager la requête',
      Subtitle: 'Sélectionnez les utilisateurs',
      Description: 'Sélectionnez les utilisateurs avec lesquels vous souhaitez partager la requête',
      ShareSuccess: 'Mise à jour de la partage effectuée avec succès',
      ShareError: 'Échec de la partage de la requête'
    },
    Confirmation: {
      DialogTitle: 'Enregistrer la recherche partagée',
      SelectName: "Choisissez un nom pour votre recherche ou conservez celui d'origine.",
      SharingAcceptedSubtitle: 'La recherche {{queryName}} a été accepté et enregistré avec succès',
      SharingAcceptedText:
        'Vous retrouverez les recherches enregistrées dans le référentiel lié au module de référence.',
      Accept: 'Enregistrer la recherche partagée'
    }
  }
}
