/* eslint-disable import/no-default-export */
export default {
  Title: 'Search results',
  SaletoolTitle: 'Sales Tool - Campaign search results',
  Search: 'Search',
  SearchPlaceholder: 'Search',
  SearchCompanyPlaceholder: 'Search for the company by name',
  SearchCompaniesAdditionalInfo: 'Please note that for companies you can use also',
  SearchCompaniesAdditionalInfoAutChe:
    'Please note that for austrians companies you can use also Company name, Vat code, Website and Company Book number. For swiss companies you can use also Vat code, Business identification number, Company name, Website, Legacy CH Number',
  NoResults: "Sorry we couldn't find any matches for",
  TryDifferent: 'Try with another keyword or use <0>Semantic search</0> to search for related terms',
  TryDifferentWithoutProsp: 'Try with another keyword.',
  Banner: {
    title: 'Activate "Prospecting" section!',
    subtitle: 'Semantic search is only available for users who have purchased the "Prospecting" section.',
    gotoAction: 'Contact {{companyName}}'
  },
  Tabs: {
    Companies: 'Companies',
    Portfolios: 'Portfolios',
    Campaigns: 'Campaigns'
  },
  SearchInformationDialog: {
    Title: 'What can be searched?',
    SubTitleWithCampaigns: 'You can search Companies, Portfolios or Campaigns',
    SubTitle: 'You can search Companies or Portfolios',
    CompaniesBy: '<strong>Companies</strong> by:',
    PortfoliosCampaignsHelpText: '<strong>Portfolios</strong> and <strong>Campaigns</strong> by name',
    PortfoliosHelpText: '<strong>Portfolios</strong> by name',
    Companies: {
      CommercialDisplay: 'Commercial Display',
      Swiss: {
        VatCode: 'Vat code',
        IdentificationNumber: 'Business identification number',
        CompanyName: 'Company name',
        Website: 'Website',
        LegacyCHNumber: 'Legacy CH Number',
        CompanyBookNumber: 'Company Book number'
      },
      Fareast: {
        CompanyId: 'Company ID'
      },
      Germany: {
        VatCode: 'VATcode',
        TaxId: 'Tax id',
        Onr: 'ONR',
        Website: 'Website'
      }
    },
    Countries: {
      ch: 'SWISS',
      at: 'AUSTRIA'
    }
  },
  ShowLocalUnits: 'Show Local Units',
  HiddenPortfolioCompany:
    'The company you are looking for is not accessible because it may belong to a portfolio hidden by another user of your organization.',
  Company: 'Company',
  Portfolio: 'Portfolio',
  Campaign: 'Campaign',
  GoTo: {
    Company: 'Company Report',
    Portfolio: 'Portfolio Management',
    Campaign: 'Sales Tool'
  }
}
