import axios, { AxiosPromise, CancelToken } from 'axios'
import _ from 'lodash'
import { AddCompaniesRequest, CreatePortfolioRequest } from '@api/enrichment/types'
import { GetFilterSuggestionsRequest, GetFilterSuggestionsResponse } from '@api/suggestions/types'
import { DomainNodesDataRequest, DomainNodesDataResponse, SearchDomainNodesRequest } from '../filters/types'
import { CustomVariablesResponse, OperationResult } from '../types'
import {
  AddLocalUnitsRequest,
  AssignEntityRequest,
  BranchTypesResponseItem,
  ComparePortfolioVariablesRequest,
  CreatePortfolioFromSelectionRequest,
  DeleteCompaniesRequest,
  GetPortfolioCompanyDuplicatesResponse,
  GetPortfoliosRequest,
  GetPortfoliosResponse,
  MoveCompaniesRequest,
  PortfolioCompaniesForMap,
  PortfolioCompaniesMapReq,
  PortfolioCompaniesRequest,
  PortfolioCompaniesResponse,
  PortfolioListItem,
  PortfolioPreview,
  PortfolioSelectListParams,
  ResolvedPortfolioCompanyDuplicate,
  ShareCompaniesRequest,
  UnshareCompaniesRequest,
  UserExclusionsItem,
  UserShareItem,
  PortfolioCompanyDetails,
  UpdatePortfolioCompanyRequest,
  CancelMonitoringRequest,
  UnlockEsgScoresRequest
} from './types'

export const createPortfolioFromFileRequest = (formData: FormData, onUploadProgress: (progressEvent: any) => void) =>
  axios
    .post<OperationResult>('/api/PortfolioActions/create-from-file', formData, {
      onUploadProgress,
      allowedErrorStatuses: [400]
    })
    .then(resp => resp.data)

export const updatePortfolioFromFileRequest = (formData: FormData, onUploadProgress: (progressEvent: any) => void) =>
  axios
    .post<OperationResult>('/api/PortfolioActions/update-from-file', formData, {
      onUploadProgress
    })
    .then(resp => resp.data)

export const createPortfolioFromCompanyIdsRequest = (
  companyIds: string[],
  portfolioName: string,
  status: string,
  country: string | undefined,
  onUploadProgress: (progressEvent: any) => void
) =>
  axios
    .post<OperationResult>(
      '/api/PortfolioActions/create-from-company-ids',
      { companyIds, portfolioName, status, country },
      {
        onUploadProgress,
        allowedErrorStatuses: [400]
      }
    )
    .then(resp => resp.data)

export const updatePortfolioFromCompanyIdsRequest = (
  companyIds: string[],
  portfolioId: string,
  status: string,
  country?: string
) =>
  axios
    .post<OperationResult>(
      '/api/PortfolioActions/update-from-company-ids',
      { companyIds, portfolioId, status, country },
      {
        allowedErrorStatuses: [400]
      }
    )
    .then(resp => resp.data)

export const createPortfolio = (requestData: CreatePortfolioRequest) =>
  axios.post<OperationResult>('/api/PortfolioActions/create', { selection: requestData }).then(res => res.data)

export const addCompanies = (requestData: AddCompaniesRequest) =>
  axios.post<OperationResult>('/api/PortfolioActions/add-companies', { selection: requestData }).then(res => res.data)

export const unlockEsgScores = (requestData: UnlockEsgScoresRequest) =>
  axios
    .post<OperationResult>('/api/PortfolioActions/unlock-esg-scores', { selection: requestData })
    .then(res => res.data)

export const createPortfolioFromPortfolioSelection = (data: CreatePortfolioFromSelectionRequest) =>
  axios.post('/api/PortfolioActions/create-new-from-portfolio-selection', data)

export const deleteCompanies = (data: DeleteCompaniesRequest) =>
  axios.post('/api/PortfolioActions/delete-portfolio-companies-from-selection', data)

export const moveCompanies = (data: MoveCompaniesRequest) =>
  axios.post('/api/PortfolioActions/move-from-portfolio-selection', data)

export const comparePortfolioVariables = (data: ComparePortfolioVariablesRequest) =>
  axios.post('/api/Portfolio/check-portfolio-variables-compatibility', data)

export const assignProduct = (data: AssignEntityRequest) => axios.post('/api/PortfolioActions/assign-product', data)

export const assignSales = (data: AssignEntityRequest) => axios.post('/api/PortfolioActions/assign-sales', data)

export const assignAreaManager = (data: AssignEntityRequest) =>
  axios.post('/api/PortfolioActions/assign-area-manager', data)

export const getPortfolioCompanies = (portfolioId: string, data: PortfolioCompaniesRequest) =>
  axios.post<PortfolioCompaniesResponse>(`/api/portfolio/${portfolioId}/portfolio-companies`, data).then(resp => {
    // eslint-disable-next-line no-param-reassign
    resp.data.data.items = _.map(resp.data.data.items, c => {
      return {
        ...c,
        cells: _.map(resp.data.configuration.fields, configField => {
          const index = _.findIndex(c.cells, { columnId: configField.jsonPath })
          return c.cells[index]
        })
      }
    })
    return resp
  }) as AxiosPromise<PortfolioCompaniesResponse>

export const getPortfolioCompanyDetails = (margoId: string, cancelToken?: CancelToken) =>
  axios
    .get<PortfolioCompanyDetails>(`/api/portfolio/portfolio-company/${margoId}`, {
      cancelToken,
      isCancelToken: cancelToken ? undefined : true
    })
    .then(resp => resp.data)

export const getPortfolioMapCompanies = (
  portfolioId: string,
  data: PortfolioCompaniesMapReq,
  cancelToken?: CancelToken
) =>
  axios.post<PortfolioCompaniesForMap>(`/api/portfolio/${portfolioId}/portfolio-company-locations`, data, {
    cancelToken,
    isCancelToken: cancelToken ? undefined : true
  })

export const validatePortfolioFile = (formData: FormData, onUploadProgress?: (progressEvent: any) => void) =>
  axios.post('/api/portfolio/validation', formData, { allowedErrorStatuses: [400], onUploadProgress })

export const getPortfolioListForTargeting = (params?: PortfolioSelectListParams, cancelToken?: CancelToken) =>
  axios.get<PortfolioListItem[]>('/api/targeting/select-list', { params, cancelToken }).then(resp => resp.data)

export const getPortfolioList = (params?: PortfolioSelectListParams, cancelToken?: CancelToken) =>
  axios.get<PortfolioListItem[]>('/api/portfolio/select-list', { params, cancelToken }).then(resp => resp.data)

export const getPortfolios = (params?: GetPortfoliosRequest) =>
  axios.get<GetPortfoliosResponse>('/api/portfolio', { params })

export const getPortfolio = (portfolioId: string) =>
  axios.post<PortfolioPreview>(`/api/portfolio/${portfolioId}/portfolio-preview`)

export const deletePortfolio = (portfolioId: string) => axios.delete(`/api/portfolio/${portfolioId}`)

export const changePortfolioName = (portfolioId: string, portfolioName: string) =>
  axios.put(`/api/portfolio/${portfolioId}/name`, { name: portfolioName }, { allowedErrorStatuses: [400] })

export const addPortfolioNote = (portfolioId: string, portfolioNote: string) =>
  axios.put(`/api/portfolio/${portfolioId}/note`, { note: portfolioNote })

// TODO unify them inder filter, remove if from saga and component and add it into api/filters based on appearance of portfolioId
export const getPortfolioDomainNodesData = (
  data: DomainNodesDataRequest,
  portfolioId: string,
  cancelToken?: CancelToken
) => axios.post<DomainNodesDataResponse>(`/api/Portfolio/${portfolioId}/domain-nodes-data`, data, { cancelToken })

export const getPortfolioSearchDomainNodesDataPath = (portfolioId: string, data: SearchDomainNodesRequest) =>
  axios.post<DomainNodesDataResponse>(`/api/Portfolio/${portfolioId}/search-domain-nodes-data`, data)

export const validateName = (name: string) =>
  axios.post('/api/Portfolio/valid-name', { name }, { allowedErrorStatuses: [400] })

export const getPortfolioCompanyDuplicates = (portfolioId: string) =>
  axios
    .get<GetPortfolioCompanyDuplicatesResponse>(`/api/portfolio/${portfolioId}/duplicates`)
    .then(response => response.data)

export const getPortfolioCompaniesByIdentificationCode = (portfolioId: string, identificationCode: string) =>
  axios
    .get<GetPortfolioCompanyDuplicatesResponse>(`/api/portfolio/${portfolioId}/company/${identificationCode}`)
    .then(response => response.data)

export const resolvePortfolioCompanyDuplicates = (
  portfolioId: string,
  resolvedDuplicates: ResolvedPortfolioCompanyDuplicate[]
) => axios.post(`/api/portfolio/${portfolioId}/duplicates`, resolvedDuplicates)

export const getUsersInPortfolio = (portfolioId: string) =>
  axios.get<UserShareItem[]>(`/api/Portfolio/${portfolioId}/user-shares`)

export const setUsersInPortfolio = (portfolioId: string, users: UserShareItem[]) =>
  axios.put<UserShareItem[]>(`/api/Portfolio/${portfolioId}/user-shares`, users)

export const getUsersExclusionsInPortfolio = (portfolioId: string) =>
  axios.get<UserExclusionsItem[]>(`/api/Portfolio/${portfolioId}/exclusions`)

export const setUsersExclusionsInPortfolio = (portfolioId: string, users: UserExclusionsItem[]) =>
  axios.put<UserExclusionsItem[]>(`/api/Portfolio/${portfolioId}/exclusions`, users)

export const shareCompanyInPortfolio = (margoId: string, withSales: boolean, withAreaManager: boolean) =>
  axios.put('api/PortfolioCompany/share-company', {
    margoId,
    shareWithSales: withSales,
    shareWithAreaManager: withAreaManager
  })

export const unshareCompanyInPortfolio = (margoId: string, withSales: boolean, withAreaManager: boolean) =>
  axios.put('api/PortfolioCompany/unshare-company', {
    margoId,
    unshareFromSales: withSales,
    unshareFromAreaManager: withAreaManager
  })

export const getBranchTypesList = () =>
  axios.get<BranchTypesResponseItem>('/api/common/branches-type').then(res => res.data)

export const addLocalUnits = (data: AddLocalUnitsRequest) =>
  axios.post('/api/PortfolioActions/add-local-units-from-portfolio-selection', data)

export const shareCompanies = (data: ShareCompaniesRequest) => axios.post('/api/PortfolioActions/share-companies', data)

export const unshareCompanies = (data: UnshareCompaniesRequest) =>
  axios.post('/api/PortfolioActions/unshare-companies', data)

export const getPortfolioCustomVariables = (portfolioId: string) =>
  axios.get<CustomVariablesResponse>(`/api/Portfolio/${portfolioId}/customVariables`).then(res => res.data)

export const updatePortfolioCompany = (updateRequest: UpdatePortfolioCompanyRequest): any =>
  axios.put(`/api/portfolio/portfolio-company/${updateRequest.margoId}`, updateRequest.companyDetails)

export const validatePortfolioCompany = (updateRequest: UpdatePortfolioCompanyRequest) =>
  axios.post(`/api/portfolio/portfolio-company/${updateRequest.margoId}/validate`, updateRequest.companyDetails, {
    allowedErrorStatuses: [400]
  })

export const cancelMonitoring = (portfolioId: string, request?: CancelMonitoringRequest) =>
  axios.put(`/api/portfolio/${portfolioId}/cancel-monitoring`, request)

export const getFiltersSuggestions = (portfolioId: string, request: GetFilterSuggestionsRequest) =>
  axios
    .post<GetFilterSuggestionsResponse>(`/api/portfolio/${portfolioId}/filters-suggestions`, request)
    .then(x => x.data)
