/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Type of analysis',
    StartAnalysis: 'To start the analysis you need to select the type of analysis you want to process',
    MarketAnalysisBtn: 'Start new market analysis',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    PortfolioQuery: 'Portfolio Management search',
    TargetingQuery: 'Targeting Query',
    ProspectingQuery: 'Prospecting Query',
    ChooseQuery: 'Choose which query you want to load',
    MarketAnalysisBtnTooltip:
      'Visualize and segment a set of companies according to the main variables and build your target',
    FromTargeting: 'Load market analysis query',
    FromTargetingTooltip: 'Open a query saved in the Analyze market module',
    FromProspecting: 'Load prospecting query',
    FromProspectingTooltip: 'Open a query saved in the Prospecting module'
  },
  ChartLabels: {
    Company: 'Company',
    Company_plural: 'Companies',
    ChartDetail: 'Chart detail',
    SaveGraph: 'Save graph',
    ChangeChart: 'Change chart',
    DeleteChart: 'Delete chart',
    PercentageOfCompanies: '% of companies',
    NumberOfCompanies: 'n° of companies',
    NumberOfEmployees: 'n° of employees',
    Amount: 'Amount',
    Risk: 'Risk level',
    Employees: 'Employees',
    Revenue: 'Turnover',
    RevenueCz: 'Turnover',
    RevenueSk: 'Turnover',
    Other: 'Other',
    Years: 'Years',
    Status: 'Status',
    HiddenNDNotice: 'Notice: this chart does not show the company class with no data',
    HiddenChartSentence:
      'Notice: In this table only the first 20 most relevant categories with related values are shown. Download the Excel file file in order to visualize all the categories and their related values in percentages.',
    EmptyChart: {
      Title: 'Choose the chart type and the variable',
      SelectVariableDescription: 'Select the variable you want to analyze and then choose the chart type',
      SelectVariableLabel: 'Select the variable',
      SelectChartLabel: 'Select the chart type',
      CompanyInformationTitle: 'Company Information',
      CompanyInformationSubTitle: 'Here you can choose between variable related to the companies details',
      CustomIndexTitle: 'Custom Index',
      CustomIndexSubTitle: 'Here you can choose between variable related to the custom index',
      CustomIndexNotEnabledTooltip: 'You have no custom index enabled on this subscription',
      DisabledCategoryTooltip: 'This category does not contain any options',
      UpdateChart: 'Update Chart',
      CreateChart: 'Create Chart',
      ChartTypes: {
        AreaChartMultiChoice: 'Area Chart MultiChoice',
        BarChart: 'Bar Chart',
        HistogramChart: 'Histogram Chart',
        GroupedBarChart: 'GroupedBar Chart',
        PieChart: 'Pie Chart',
        TreeMap: 'Tree Map',
        Map: 'Map'
      }
    }
  },
  Header: {
    Targeting: 'Analyze market',
    Title: 'Market Analysis',
    LoadQuery: 'Load Query',
    LoadQueryTooltip: 'Open a saved query',
    SaveQuery: 'Save Query',
    SaveQueryTooltip: 'Save this query in the Analyze market module',
    Download: 'Download PDF/Excel',
    DownloadTooltip: 'Download a report of this query on your device',
    LoadPortfolio: 'Load Portfolio',
    LoadPortfolioTooltip: 'Open and analyze a portfolio',
    SendToProspecting: 'Send query to Prospecting',
    SendToProspectingTooltip: 'Display the list of companies that belong to this query',
    SendToProspectingFromPortfolioTooltip: 'Show prospect companies that match the search criteria',
    SendToProspectingMessage: 'To enable this button, apply a filter to your analysis',
    SaveList: 'Save List',
    SaveListTooltip: 'Save this companies list into a portfolio',
    SendToOtherModules: 'Send to other modules',
    SendToOtherModulesTooltip: 'Send this companies list to Portfolio management or Prospecting'
  },
  Filters: {
    CompanyCount: 'Company',
    CompanyCount_plural: 'Companies',
    ActiveCompanyCount: 'Active',
    ActiveCompanyCount_plural: 'Active',
    OtherCompanyCount: 'Other',
    StatusSelectLabel: 'Choose status',
    StatusSelectTooltip:
      'It has been selected the first available Status Company value. In order to change the Status Company, select a value from the dropdown below.',
    ShowBySelectLabel: 'Show by',
    AggregationFieldAmount: 'Amount',
    AggregationFieldNumCompanies: 'Count of companies',
    DisabledFilterTooltip: 'The analyses on this page concern only the Headquarters.'
  },
  Search: {
    SemanticSearch: 'Semantic search',
    SearchPlaceholder: 'Find companies by keyword about business activity or product (e.g. solar panels)',
    All: 'All',
    OfficialData: 'Official data',
    Website: 'Web & Social',
    Synonyms: 'Synonyms',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Advanced',
    AdvancedTooltip:
      'With the Advanced semantic search you can use multiple words linked by logical operators (AND, OR, NOT). To enable it, turn the switch to ON. Download the document for more informations.',
    DownloadSsGuide: 'Download semantic search guide'
  },
  DomainFilter: {
    SaveAndClose: 'Save filters & close'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Choose which Prospecting query you want to load',
    IntroSectionTitleTargeting: 'Choose which Targeting query you want to load',
    IntroSectionDescription: 'You can resume a research that you have already started.',
    DialogTitle: 'Load saved research',
    DialogSubTitle: 'Select saved search',
    DialogDescription:
      'Choose which analysis you want to continue, you can resume the analysis from one of the saved ones.',
    SelectLabel: 'Saved query',
    Load: 'Load',
    LoadingMessage: 'Loading your analysis',
    InvalidPortfolioTitle: 'Invalid portfolio',
    InvalidPortfolioMessage:
      'The portfolio saved in the query is not valid anymore.\nYour analysis will be based on all the companies in the {{companyName}} database.',
    TargetingRadio: 'Targeting query',
    ProspectingRadio: 'Prospecting search'
  },
  LoadPortfolio: {
    DialogTitle: 'Load portfolio',
    DialogSubTitle: 'Select a portfolio',
    Description: 'Choose on which portfolio you want to perform your analysis.',
    SelectLabel: 'Saved portfolio',
    Load: 'Load',
    LoadingMessage: 'Loading portfolio'
  },
  SaveBeforeContinuing: {
    Question: 'Do you want to save your market search before loading the search?',
    Description: 'Save your market search in a new one before loading the query to avoid losing data.',
    OkBtn: 'Save search',
    CancelBtn: 'Skip save'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Select the portfolio you want to analyze',
      Description:
        'You can process the analysis from one or more of your portfolios or view the aggregated analysis of all the companies in the {{companyName}} system.',
      PortfolioOption: 'Select portfolio',
      AggregateOption: 'All companies in the {{companyName}} System',
      UploadedPortfolio: 'Uploaded portfolio'
    },
    StepTwo: {
      Header: 'Choose the status you want to analyze',
      DescriptionLine1: 'Select the initial status of the companies to be analyzed.',
      DescriptionLine2: 'During the analysis you can change the status using filters.',
      Status: 'Status'
    }
  },
  Download: {
    ModalTitle: 'Download PDF/Excel',
    SelectType: 'Select format',
    SelectTypeDescription:
      'You can download the complete report about your analysis in two different form; select the type of list you want to download.',
    DownloadAnalysis: 'Download your analysis',
    Pdf: 'PDF',
    PdfDescription: 'Download in .pdf format',
    PdfRecap: 'You are downloading a file containing all the result of this analysis as a pdf.',
    Excel: 'Excel',
    ExcelDescription: 'Download in .xlsx format',
    ExcelRecapLine1: 'You are downloading a file containing all the result of this analysis as a list.',
    ExcelRecapLine2: 'It will be saved in the "Analyze Market" section of your account.',
    CompanyInPortfolio: 'Company in this portfolio',
    CompanyInPortfolio_plural: 'Companies in this portfolio',
    CompanyInAnalysis: 'Analyzed company',
    CompanyInAnalysis_plural: 'Analyzed companies',
    DownloadPdf: 'Download PDF',
    DownloadPdfPleaseWait: 'Please wait while your PDF report is being generated',
    DownloadAllChartsMustBeLoaded: 'To download the report, please wait for all charts to have been loaded',
    DownloadPdfDisabledIE: 'Download PDF not supported on this version of IE. To download it, update your browser.',
    DownloadPdfTypeChoose: 'Download both data and graphs?',
    PdfPage: {
      Title: 'Analysis report',
      CompanyPortfolioStatus: 'Companies status in portfolio',
      DataShownBy: 'Data shown by',
      ExcludedPortfolios: 'Excluded portfolios'
    }
  },
  UnavailableForMobile: 'The page you are trying to access is only available for tablets and desktops.',
  ChartFetchError: 'An error occurred while loading this chart.',
  ChartFetchRetry: 'Retry',
  ChartEmpty: 'No data for this chart',
  ChartErrorCompanyLimit: 'Select at least {{minNumberOfCompanies}} companies in order to see the chart.',
  BasedOnHeadquarter: 'The numbers shown are related to the administrative headquarter',
  VisualizeAs: {
    List: 'Visualize as list',
    Map: 'Visualize on map'
  },
  WarningRespectingLocalUnits: {
    Title: 'Enrichment',
    Text: 'Companies with Office type "Administrative Headquarter", "Administrative Headquarter and Registered office" and "Registered office" have been enriched (if selected). To enrich companies with Office type "Local unit", please activate the Local Units addon by contacting the customer service.'
  },
  SendToOtherModulesDialog: {
    Title: 'Select module',
    SubTitle: 'Select the module where you want to send your analysis or list to',
    PortfolioManagement: 'Portfolio management',
    PortfolioManagementDescription:
      'Click here to go back to the Portfolio preserving the searching criteria applied during the analysis and to consult the list of obtained companies.',
    Prospecting: 'Prospecting',
    ProspectingDescription:
      'Click here to send the list of companies identified in the market research to the Prospecting module to see the list of companies, consult their reports and possibly refine your search by selecting other criteria and filters.',
    SelectPortfolio: 'Select portfolio',
    OkButton: 'Send'
  }
}
