import { SemanticSearchType } from '@api/semanticSearch/types'
import { OrderByDirection, SynonymItem } from '../../../api/types'
import { TreeKeySelection } from '../../../utils/tree'

// TODO refactor to enum
export type FilterReducerPath = 'views.portfolioDetail' | 'prospecting' | 'views.salestoolAssignments' | 'targeting'

export enum FilterType {
  Unknown = 'Unknown',
  Text = 'Text',
  NumericRange = 'NumericRange',
  LongRange = 'LongRange',
  DateRange = 'DateRange',
  BooleanToggle = 'BooleanToggle',
  SingleUnknownValue = 'SingleUnknownValue',
  Domain = 'HierarchicalDomain',
  ListAsDomain = 'ListAsHierarchicalDomain',
  IsOneOfInTheList = 'IsOneOfInTheList',
  IsNotOneOfInTheList = 'IsNotOneOfInTheList',
  TextArray = 'TextArray',
  EnrichBoolean = 'EnrichBoolean',
  Map = 'Geo'
}

export type Filter = {
  fieldId: string
  type: FilterType
  name: string
  suffix?: string
  description?: string
  parameters: Record<string, any>
  orGroup?: string
  allowMultiSelection?: boolean
  allowSuggestions?: boolean
}

export type Filters = Record<string, Filter[]>

export enum FilterOptionType {
  Simple = 'Simple',
  Hierarchy = 'Hierarchy',
  Container = 'Container'
}

export type FilterDefinition = {
  id: string
  name: string
  enabled: boolean
  description?: string
  position?: number
  isCustomVar?: boolean
  type: FilterOptionType
  children: FilterDefinition[]
  parentPath?: string[]
  isMostPopularFilter?: boolean
  hasExactSearch?: boolean
  exactSearchTooltip?: string
}

export type FilterGroup = {
  id: string
  name: string
  showInfo: boolean
  description?: string
  type: FilterOptionType
  children: FilterDefinition[]
}

export type SelectedFilterGroups =
  | Record<string, Record<string, Record<string, TreeKeySelection[]>>>
  | Record<string, Record<string, TreeKeySelection[]>>

export type SemanticSearch = {
  synonyms: SynonymItem[]
  appliedSynonyms: SynonymItem[]
  searchText: string
  type: SemanticSearchType
  advanced: boolean
  not: boolean
}

export type SimilarityType = {
  portfolioId: string
  status: string
  companies: number | undefined
  data?: {
    includeSemanticClusters: boolean
  }
}

export type OrderByOption = {
  key: string
  jsonPath: string
  text: string
  direction: OrderByDirection
}

export type OrderByOptions = OrderByOption[]

export type FilterState = {
  selectingFilters?: string
  selectedFilterGroups: SelectedFilterGroups
}

export type FilterStateWrapper = {
  filters: FilterState
}

export type ReducerWithFiltering<T> = T & FilterStateWrapper
