/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Your account',
  Navigation: {
    Account: 'Account',
    SavedReport: 'Saved report',
    PortfolioManagement: 'Portfolio Management',
    Targeting: 'Analyze Market',
    Prospecting: 'Prospecting',
    Operations: 'Operations',
    SalesTool: 'Sales Tool',
    Products: 'Products',
    GoalsTasks: 'Goal and Tasks',
    ReleaseNotes: 'Latest News',
    Configuration: 'Configuration',
    Appointments: 'Appointments',
    Contacts: 'Contacts'
  },
  Goal: {
    AddGoal: 'Add goal',
    AddGoalTooltip: 'Create a goal to choose in the commercial campaigns',
    EditGoal: {
      Title: 'Change goal name',
      Body: 'Enter a representative name for the goal',
      NameInput: 'Goal name',
      Success: 'Goal successfully modified.',
      Error: 'There was error while modifying goal.'
    },
    DeleteGoal: {
      Title: 'Delete goal',
      Subtitle: 'Are you sure you want to delete {{goalName}}?',
      Body: 'Action will be performed only if mentioned goal has not been assigned to any active campaigns; otherwise, action will be denied.',
      Success: 'Goal was successfully deleted.',
      Error: 'There was error while deleting goal.',
      ErrorAssignedToActiveCampaign:
        "It's not possible to delete the selected goal, since there's an active campaign which has been assigned with the mentioned goal.",
      ErrorActiveActionsForSameOrganization:
        "The operation couldn't be completed because of other pending actions. Please, retry in some minutes."
    },
    EditTask: {
      Title: 'Change task name',
      Body: 'Enter a representative name for the task',
      NameInput: 'Task name',
      Success: 'Task successfully modified',
      Error: 'There was error while modifying task.'
    },
    DeleteTask: {
      Title: 'Delete task',
      Subtitle: 'Are you sure you want to delete {{taskName}}?',
      Body: 'Action will be performed only if mentioned goal has not been assigned to any active campaigns; otherwise, action will be denied.',
      Success: 'Task successfully deleted',
      Error: 'There was error while deleting task.',
      ErrorAssignedToActiveCampaign:
        "It's not possible to delete the selected task, since there are companies in active campaigns which has been assigned with the mentioned task. For proceeding with the deletion, remove the assignation inside the campaign and try again to remove the task.",
      ErrorActiveActionsForSameOrganization:
        "The operation couldn't be completed because of other pending actions. Please, retry in some minutes."
    },
    CreateGoal: 'Create goal',
    NameDesc: 'Enter a representative name of the goal you are creating.',
    GoalNamePlaceholder: 'Insert goal name',
    GoalNameLabel: 'Goal name',
    TasksHeader: 'Choose the tasks to reach your goal',
    TaskDesc:
      'Insert all task needed to reach this goal (min 3, max 10). Lower numbers identify a low completion rate of goal.',
    TaskPlaceholder: 'Insert task name',
    TaskNumber: 'Task number',
    LastTaskDesc: '* This task will be considered as the final task of the campaign',
    AddTask: 'Add task',
    Number: 'Number',
    Task: 'Task',
    Desc1: 'The number near the task identifies the level of completion of the goal.',
    Desc2: 'Lower numbers identify a low completion rate of goal.',
    Table: {
      Name: 'Name',
      NumberOfTasks: 'N° of tasks'
    },
    EmptyText: 'Here you will find all the goals and tasks saved in the "goal and task section"',
    Visualize: 'Visualize {{goal}}'
  },
  Queries: {
    SearchPlaceholder: 'Find query',
    Table: {
      Name: 'Saved Query',
      CreatedAt: 'Save date',
      LastUseDate: 'Last use date',
      Monitored: 'Followed',
      ResumeQuery: 'Resume Query',
      Delete: 'Delete',
      ResumePortfolioQueryTooltip: 'Open "Portfolio Management" with preselected filters from {{queryName}}',
      ResumeProspectingQueryTooltip: 'Open "Prospecting" with preselected filters from {{queryName}}',
      ResumeTargetingQueryTooltip: 'Open "Analyze Market" with preselected filters from {{queryName}}'
    },
    EmptyProspectingText: 'Here you will find all the Queries saved in "Prospecting" section',
    EmptyTargetingText: 'Here you will find all the Queries saved in "Analyze Market" section',
    DeleteDialog: {
      Title: 'Delete saved query?',
      Title_plural: 'Delete saved queries?',
      Subtitle: 'Confirm to proceed with the operation',
      Body: 'Do you want to delete the saved query?',
      Body_plural: 'Do you want to delete the saved queries?',
      Monitored: 'If the query is monitored, the relative portfolio will no longer be updated',
      Success: 'Query was successfully deleted.',
      Success_plural: 'Queries was successfully deleted.',
      Error: 'There was error while deleting your query.',
      Error_plural: 'There was error while deleting your queries',
      MonitoredQueryError:
        'Some of the selected queries cannot be deleted because they are monitored queries. In order to delete these queries, please delete the related monitored portfolios in first place.'
    },
    ResumePortfolioQueryDialog: {
      Title: 'Choose the portfolio you want the query is applied to',
      Text: 'Choose one of the available portfolios.'
    },
    Actions: {
      EmptyDeleteSelectionTitle: 'Delete queries',
      EmptySelectionText: 'You need to select at least one query',
      Delete: 'Delete'
    }
  },
  Products: {
    Table: {
      Name: 'Product',
      CreatedAt: 'Date of insertion'
    },
    AddProduct: 'Add Product',
    AddProductTooltip: 'Create a product to assign in the commercial campaigns',
    EditProduct: {
      Title: 'Change product name',
      Body: 'Enter a representative name for the product',
      NameInputLabel: 'Product name',
      NameInputPlaceholder: 'Product name',
      Success: 'Product successfully modified.',
      Error: 'There was error while modifying product.'
    },
    DeleteProduct: {
      Title: 'Delete product',
      Subtitle: 'Are you sure you want to delete {{productName}}?',
      Body: 'Action will be performed only if mentioned product has not been assigned to any active campaigns; otherwise, action will be denied.',
      Success: 'Product was successfully deleted.',
      Error: 'There was error while deleting product.',
      ErrorAssignedToActiveCampaign:
        "It's not possible to delete the selected product, since there's an active campaign which has been assigned with the mentioned product.",
      ErrorActiveActionsForSameOrganization:
        "The operation couldn't be completed because of other pending actions. Please, retry in some minutes."
    },
    EmptyText: 'Here you will find all the products saved in "Products" section',
    CreateProduct: 'Create product',
    NameDesc: 'Enter a representative name of the product you are creating.',
    ProductNamePlaceholder: 'Insert product name',
    ProductNameLabel: 'Product name'
  },
  PortfolioManagement: {
    EmptyText: 'Here you will find all the documents saved in the Portfolio Management section',
    Documents: 'Documents',
    DocumentsTable: {
      Name: 'Document',
      CreatedAt: 'Saved date',
      MimeType: 'Extension'
    },
    Queries: 'Queries',
    DeleteDialog: {
      Body: 'Do you want to delete document?',
      Body_plural: 'Do you want to delete documents?',
      Title: 'Delete document?',
      Title_plural: 'Delete documents?',
      Subtitle: 'Confirm to proceed with the operation',
      Success: 'Document was successfully deleted.',
      Success_plural: 'Documents was successfully deleted.',
      Error: 'There was error while deleting your document.',
      Error_plural: 'There was error while deleting your documents'
    },
    Actions: {
      EmptySelectionText: 'You need to select at least one document',
      EmptyDeleteSelectionTitle: 'Delete documents',
      Delete: 'Delete',
      Download: 'Download',
      ShareTrack: 'Share track'
    },
    CustomTracks: {
      Title: 'Custom Tracks',
      EditCustomTrack: 'Edit Custom Track',
      EditSuccessMessage: 'Custom track successfully modified.',
      EditErrorMessage: 'An error occurred while editing custom track.',
      VisualiseCustomTrack: 'Visualise Custom Track',
      Table: {
        TrackName: 'Track name',
        SaveDate: 'Saved date',
        LastUseDate: 'Last use date',
        OwnerFullName: 'Creator name',
        ModuleOfOrigin: 'Module of origin'
      },
      DeleteDialog: {
        Body: 'Do you want to delete custom track?',
        Body_plural: 'Do you want to delete custom tracks?',
        Title: 'Delete custom track',
        Title_plural: 'Delete custom tracks',
        Subtitle: 'Confirm to proceed with the operation.',
        Success: 'Custom track was successfully deleted.',
        Success_plural: 'Custom tracks were successfully deleted.',
        Error: 'An error occurred while deleting your custom track.',
        Error_plural: 'An error occurred while deleting your custom tracks.'
      },
      RenameDialog: {
        Title: 'Change custom track name',
        Body: 'Enter a representative name for the custom track',
        NameInput: 'Custom track name',
        Success: 'Custom track successfully renamed.',
        Error: 'An error occurred while renaming custom track.'
      },
      EmptyDialog: {
        Title: 'Delete custom tracks',
        Text: 'You need to select at least one custom track.'
      },
      ShareCustomTrackErrorDialog: {
        Title: 'Sharing custom track',
        Text: 'An error occurred while sharing the custom track.'
      }
    }
  },
  Operations: {
    EmptyText: 'Here you will find all the queries saved in the Operations section',
    Table: {
      ResumePortfolioQueryTooltip: 'Open "Operations" with preselected filters from {{queryName}}'
    }
  },
  Info: {
    User: 'User',
    LoginAs: 'Login as another user',
    Level: 'Level',
    Office: 'Office',
    Subscription: 'Subscription',
    NameAndSurname: 'Name and Surname',
    JobTitle: 'Job title',
    Telephone: 'Telephone',
    CompanyName: 'Company name',
    Country: 'Country'
  },
  Account: {
    Password: {
      Password: 'Password',
      Current: 'Current',
      ChangePasswordTitle: 'Change password',
      ChangePassword: 'Change password',
      EnterPassword: 'Enter your new password',
      OldPassword: 'Old password',
      NewPassword: 'New password',
      ConfirmPassword: 'Confirm password'
    },
    Language: {
      Current: 'Current',
      ChangeLanguageTitle: 'Change language',
      ChangeLanguage: 'Change the language of the contents'
    },
    Currency: {
      Title: 'Currency',
      Current: 'Current',
      ChangeCurrencyTitle: 'Change currency',
      ChangeCurrency: 'Change the currency of the contents'
    },
    Subscription: {
      Title: 'My subscription (rings)',
      Available: 'Available',
      AvailableTooltip: 'Rings leftover on the subscription available for your account',
      Used: 'Used',
      UsedTooltip: 'Rings spent by your account',
      Total: 'Total',
      TotalTooltip: 'Rings purchased for your account',
      Validity: 'Validity',
      ValidityInfo: 'From {{from}} to {{to}}',
      CreditInfoWithoutCompanyName: ' to update your subscription or buy more rings',
      Error: 'Consumption limits could not be loaded.',
      EsgError: 'Error loading ESG rings, please try again later.'
    },
    SubscriptionSettings: {
      Title: 'Subscription Settings',
      OrganizationCode: 'Subscription Code:',
      ButtonMessage: 'View',
      ContactUsSecondary: ' to purchase additional features',
      ViewAddons: {
        StandardEnablings: 'Standard Enablings:',
        CustomEnablings: 'Custom Enablings:',
        Analytics: 'Analytics indexes',
        PdfReport: 'PDF Report',
        LocalUnits: 'Local units',
        RetailFilters: 'Retail Filters',
        ElectricityConsumptionScore: 'Electricity consumption score',
        GasConsumptionScore: 'Gas consumption score',
        PhoneConsumptionScore: 'Phone consumption score',
        MobilePhoneConsumptionScore: 'Mobile phone consumption score',
        MargoSemanticClusters: 'Margo Semantic Clusters',
        NegativeEvents: 'Negative events',
        SupplierScore: 'Supplier score',
        Latitude: 'Latitude',
        Longitude: 'Longitude',
        MarketingList: 'Marketing List',
        CompleteCustomLists: 'Complete/Custom Lists'
      }
    },
    Contact: {
      Title: 'Contacts',
      User: 'User',
      Email: 'Email',
      UpdateInfoWithoutCompanyName: ' to change your contacts'
    }
  },
  TargetingTab: {
    SavedQuery: 'Saved query',
    DownloadedDocuments: 'Downloaded documents'
  },
  QueryDetail: {
    VisualiseQuery: 'Visualise Query',
    Monitored: 'Followed'
  },
  ReleaseNotes: {
    Title: 'Latest News',
    NewNote: 'New release note',
    NoteTitle: 'Title',
    NoteDescription: 'Short description',
    NoteContent: 'Content',
    WhatsNew: "What's new",
    GoToNewsPage: 'Go to the Latest News page to see what else is new',
    EmptyText: 'Here you will find all the release notes saved in the "Latest News" section'
  },
  Configuration: {
    ConfigureMargoTitle: 'Configure Margo',
    CreateOfficeConfiguration: 'Create office configuration',
    CreateUserConfiguration: 'Create user configuration',
    EmptyText: 'Here you will find saved configurations.',
    SearchOfficePlaceholder: 'Search for Office name',
    SearchUserPlaceholder: 'Search for User name',
    ViewConfigurationTitle: 'Configuration for',
    AddConfiguration: {
      OfficeTitle: 'Select one or more offices',
      OfficeDescription:
        'Select one or more offices you want to create a configuration for.\nYou are creating an office configuration, which is applied to all present and future users. You can change the configuration at anytime.',
      UsersTitle: 'Select one or more users',
      UsersDescription:
        'Select one or more users you want to create a configuration for.\nIf user belongs to a configured office, the user configuration will overwrite the office one.',
      ParametersTitle: 'Select parameters',
      ParametersDescription:
        'Select the parameters on which companies will be filtered.\nIf you selected a user belonging to a configured office, below list present the Office applied filter.\nYou can overwrite Office Configuration only for selected users, editing or deleting all filters.',
      FullMargoLabel: 'Disable all filters and display full Margo',
      ConfigurationSuccessful: 'Configuration successfully applied',
      ConfigurationSuccessfulDescription: 'You can change the configuration from Your Account at any time'
    },
    DeleteConfiguration: {
      Title: 'Delete configuration',
      Subtitle: 'Are you sure you want to delete the configuration?',
      Body: 'User visibility will no longer be limited to the parameters selected during configuration.',
      Success: 'Configuration deleted',
      Error: 'There was error while deleting saved configuration.',
      EmptySelectionText: 'You need to select at least one configuration'
    },
    Table: {
      UserName: 'User name',
      OfficeName: 'Office name',
      Creator: 'Creator of the configuration',
      UpdatedAt: 'Last update date'
    },
    Offices: 'Offices',
    Users: 'Users',
    UserTabTitle: 'User',
    OfficeTabTitle: 'Office',
    AppliedFilterTabTitle: 'Applied filters',
    AlreadyConfigured: 'Already configured',
    FullMargoLabelForView: 'No applied filters: the user views full Margo'
  },
  Esg: {
    Title: 'ESG unlocks',
    UnlockedSubtitle: 'Scores unlocked',
    ContactToUpdate: ' to update your payment plan'
  }
}
