import { MAX_FILE_NAME_LENGTH } from '../../pages/CompanySurveys/constants'
/* eslint-disable import/no-default-export */
export default {
  MainTitle: 'Nome/Ragione Sociale',
  Actions: 'Azioni',
  DownloadPDF: 'Scarica PDF',
  AddToCampaign: ' Aggiungi alla Campagna',
  CompanyData: {
    CompanyName: 'Nome/Ragione Sociale',
    Title: 'Anteprima',
    Town: 'Città',
    OfficeClassification: 'Tipo di sede',
    Ateco2007: 'Ateco Primario (2007)',
    LegalForm: 'Forma legale',
    LastUpdate: 'Data ultimo aggiornamento',
    TaxCode: 'Codice Fiscale',
    VatCode: 'Partita Iva',
    ZipCity: 'Codice postale della città',
    MainIndustrialSectors: 'Principali settori industriali',
    IdentificationNumber: 'Identificativo Azienda',
    CommercialDisplay: 'Insegna'
  },
  CompanySummary: {
    Evaluation: 'Valutazione',
    Profile: 'Profilo',
    AffidabilityScore: 'Score di Affidabilità',
    CompanyGroup: 'Legami societari',
    PublicContracts: 'Bandi pubblici',
    LocalUnits: 'Unità locali',
    CompanyStatus: 'Stato impresa',
    Title: 'Sintesi',
    Address: 'Indirizzo sede amministrativa',
    RegisteredOffice: 'Indirizzo sede legale',
    Town: 'Comune',
    State: 'Stato',
    TaxCode: 'Codice Fiscale',
    VatCode: 'Partita Iva',
    CrifNumber: 'CRIF ID',
    CCIAAREA: 'CCIAA / REA',
    TotalNumberBranches: 'Numero totale di unità locali',
    TotalNumberLocalUnit: 'Numero unità locali',
    GroupTurnover: 'Fatturato Consolidato',
    DetailedLegalForm: 'Forma legale dettagliata',
    Ateco2007: 'Ateco Primario (2007)',
    SIC: 'SIC',
    NACE: 'Nace',
    StartDate: 'Data inizio attività',
    IncorporationDate: 'Data costituzione',
    RegistrationCamComDate: 'Data iscrizione in Camera di Commercio',
    HoldingCompanyName: 'Nome della capogruppo',
    NationalParentCompany: 'Casa madre nazionale',
    TenderWon3y: 'Bandi vinti (ultimi 3 anni)',
    TenderParticipated3y: 'Bandi partecipati (ultimi 3 anni)',
    TenderValueWon3y: 'Importo aggiudicato dai bandi vinti (ultimi 3 anni)',
    TypeOffice: 'Tipo unità locale',
    CountOffice: 'Numero unità locali per tipo',
    Turnover: 'Fatturato',
    Estimated: 'Stimato',
    Profit: 'Utile',
    Employees: 'Dipendenti',
    Mol: 'MOL',
    DetectionDate: 'Data di pubblicazione',
    Ebitda: 'EBITDA',
    Ebit: 'EBIT',
    GroupVatCode: 'Partita iva gruppo',
    MainIndustrialSectors: 'Principali settori industriali',
    SecondaryIndustrialSectors: 'Settori industriali secondari',
    StartOfBusiness: "Inizio dell'attività",
    DateOfFoundation: 'Date of foundation',
    TotalNumberOfVehicles: 'Numero totale di veicoli',
    NumberOfPassengerCars: 'Numero di autovetture',
    CarpoolNumberOfLorries: 'Carpool - numero di camion',
    tlcFix: 'Spese in TLC fisso (€)',
    tlcMob: 'Spese in TLC mobile (€)',
    tlcFixTrend: 'Trend spese in TLC fisso (%)',
    tlcMobTrend: 'Trend spese in TLC mobile (%)',
    SemanticClusters: 'Classi Settoriali Margò',
    SemanticCluster: {
      Tooltip: 'La tua azienda non appartiene alla Classe Settoriale Margò assegnata?',
      TooltipNotAssigned: 'Vuoi assegnare una Classe Settoriale Margò alla tua impresa?',
      NoSemanticCluster: 'Nessuna Classe Settoriale Margò assegnata a questa impresa.',
      TextNoClusterAssigned: 'Contattaci per assegnare una Classe Settoriale Margò alla tua impresa.',
      Text: 'Contattaci per modificare la Classe Settoriale Margò assegnata alla tua impresa.',
      CompanyName: 'Ragione sociale',
      IdentificationCode: 'Partita Iva, Codice Fiscale, CRIF Number',
      SemanticCluster: 'Classe Settoriale Margò',
      ConfirmationDialogTitle: 'Classi Settoriali Margò',
      ConfirmationDialogWarning: 'Richiesta inviata con successo',
      ConfirmationDialogText:
        'Abbiamo preso in carico la tua richiesta. Valuteremo la sostituzione della Classe Settoriale Margò associata alla tua impresa.',
      ContactUs: 'Segnalacelo',
      Source: 'Fonte',
      SourcePlaceholder: 'Inserisci un sito web per verificare la Classe Settoriale Margò assegnata',
      SemanticClusterPlaceholder: 'Seleziona la Classe Settoriale Margò più adatta alla tua impresa'
    },
    BankInformation: 'Informazioni Bancarie',
    ImportExport: 'Import/Export',
    Registrations: 'Registrazioni',
    AuditingCompany: 'Società di revisione',
    IsoCodes: 'Certificazioni ISO',
    StateAids: {
      Title: 'Aiuti di Stato',
      Type: 'Tipology',
      Tooltip: 'Le informazioni riguardanti gli Aiuti di Stato fanno riferimento agli ultimi due anni',
      Category: 'Categoria',
      Amount: 'Importo',
      Transferor: 'Cedente'
    },
    DunsNumber: {
      Headquarter: 'DUNS Number sede amministrativa',
      RegisteredOffice: 'DUNS Number sede legale'
    },
    EmployeesOnPermanentContract: 'Percentuale dipendenti contratto a tempo indeterminato',
    EmployeesOnFixedTermContract: 'Percentuale dipendenti contratto a tempo determinato',
    EmployeesOnFullTimeContract: 'Percentuale dipendenti contratto a tempo pieno',
    EmployeesOnPartialTimeContract: 'Percentuale dipendenti contratto a tempo parziale',
    EmployeesStatistics: 'Statistiche dipendenti',
    EmployeesQualification: 'Qualifica dipendenti',
    EmployeesWithQualificationDirector: 'Percentuale dipendenti con qualifica "Dirigente"',
    EmployeesWithQualificationFrameworkContract: 'Percentuale dipendenti con qualifica "Quadro"',
    EmployeesWithQualificationEmployee: 'Percentuale dipendenti con qualifica "Impiegato"',
    EmployeesWithQualificationApprentice: 'Percentuale dipendenti con qualifica "Apprendista"',
    EmployeesWithQualificationWorker: 'Percentuale dipendenti con qualifica "Operai"',
    EmployeesWithQualificationOtherEmployees: 'Percentuale dipendenti con qualifica "Altro"',
    CommercialDisplay: 'Insegna',
    FubIndexLabel:
      'Segnaliamo che a seguito delle verifiche effettuate da Cribis D&B di cui al D.P.R. n° 178/2010, il presente dato è iscritto nel Registro Pubblico delle Opposizioni per la specifica tipologia.',
    Entrepreneurship: 'Imprenditoria',
    SoaCertification: 'Certificazione SOA'
  },
  FinancialStatement: {
    Title: 'Bilancio',
    Subtitle: 'Principali indicatori di bilancio negli ultimi 3 anni',
    Text: 'Le voci di bilancio previste dai diversi documenti contabili (Stato patrimoniale, Conto economico, Indici di bilancio) sono disponibili nel file excel scaricabile dalla piattaforma.',
    EconomicData: 'Dati economici',
    FinancialData: 'Dati finanziari',
    FinancialRatios: 'Indici di Bilancio',
    ProductionValue: 'Valore della produzione',
    ProductionCosts: 'Costi della produzione',
    BalanceOfRevenues: 'Saldo proventi / Oneri finanziari',
    ProfitLoss: 'Utile / Perdita',
    CashFlow: 'Cash Flow',
    Assets: 'Risorse',
    TotalAssets: 'Totale Attivo',
    CurrentAssets: 'Attività correnti',
    Liabilities: 'Responsabilità',
    LiabilitiesAccruals: 'Ratei passivi',
    TotalLiabilitiesAndEquity: 'Totale passività e patrimonio netto',
    ShareholdersEquity: 'Patrimonio netto',
    Provisions: 'Disposizioni',
    PFN: 'PFN = Debiti finanziari (a BT e MLT) - disponibilità immediate - disponibilità differite',
    TotalCredits: 'Totale crediti',
    TotalDebts: 'Totale debiti',
    NetWorth: 'Patrimonio netto',
    EBITDA: 'EBITDA',
    ROE: "ROE (Utile-Perdita d'esercizio / Patrimonio Netto)",
    ROI: 'ROI (MON / Totale Attivo)',
    ROS: 'ROS (MON / Valore della Produzione)',
    TotalEmployees: 'Totale dipendenti',
    Turnover: 'Fatturato',
    GroupTurnover: 'Fatturato Consolidato',
    Profit: 'Profitto',
    RegisterCapital: 'Capitale social',
    BalanceSheetRatios: 'Rapporti di bilancio',
    Sales: 'Vendite',
    Cost: 'Costo',
    FinancialAssets: 'Attività finanziarie',
    BalanceSheetProfitLost: 'Utile / perdita di bilancio (+/-)',
    Equity: 'Equity',
    LiquidityRatio: 'Liquidity ratio',
    ReturnOnTotalCapital: 'Return on total capital',
    BalanceSheetRatio: 'Rapporto di bilancio',
    TurnoverAndTurnoverTrend: 'Andamento del fatturato e del fatturato',
    TurnoverTrend: 'Trend fatturato',
    EmployeeEmployeetrend: 'Andamento dei dipendenti e dei dipendenti',
    EmployeesTrend: 'Trend dipendenti',
    RequestBalance: 'Richiedi Bilanci',
    InvestmentAssets: 'Beni di investimento',
    ImmaterialAssets: 'Beni immateriali',
    PropertyEquipment: 'Attrezzature di proprietà',
    Inventories: 'Inventari',
    ReceivablesAndOtherAssets: 'Crediti e altre attività',
    CashInhandBalancesWithCreditInstitutions: 'Disponibilità liquide presso istituti di credito',
    FinancialInvestments: 'Investimenti finanziari',
    Securities: 'Titoli',
    Accruals: 'Ratei',
    CapitalReserves: 'Riserve di capitale',
    ProfitReserves: 'Riserve di utili',
    AccumulatedProfitLoss: 'Perdita di profitto accumulata',
    UntaxedReserves: 'Riserve non tassate'
  },
  People: {
    Title: 'Persone',
    From: 'Dal',
    Amount: 'Ammontare',
    Exponents: 'Esponenti',
    Shareholders: 'Soci',
    Partners: 'Partecipazioni',
    Years: 'anni',
    OfficialSourceData: 'Dati da fonte ufficiale',
    UnofficialSourceData: 'Dati da fonte non ufficiale',
    DateOfBirth: 'Data di nascita',
    TypeOfConnection: 'Tipo di connessione',
    GoToLinkedin: 'Vai su LinkedIn',
    Visualize: {
      PersonData: 'Visualizza informazioni esponente',
      CompanyData: 'Visualizza informazioni impresa'
    }
  },
  ContactsAndSocial: {
    Title: 'Contatti & Social',
    Social: 'Social',
    RepresentativeCompany: 'Representative Company',
    CertifiedMail: 'Email certificata (PEC)',
    PublicEmail: 'Mail pubblica',
    Website: 'Sito web',
    Ecommerce: 'E-commerce',
    Fax: 'Fax',
    Telephone: 'Telefono',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Youtube: 'Youtube',
    Linkedin: 'LinkedIn',
    Pinterest: 'Pinterest',
    Vimeo: 'Vimeo',
    LoadMore: 'Carica altro'
  },
  MyContacts: {
    Title: 'Miei contatti'
  },
  Analytics: {
    Title: 'Analytics',
    Seasonalit: 'Score di stagionalità',
    Propensity: 'Score di propensione',
    AnalyticsMarketing: 'Analytics Marketing',
    AnalyticsFinance: 'Analytics Finance',
    AnalyticsMarketingMobile: 'Marketing ',
    AnalyticsFinanceMobile: 'Finance',
    SeasonalitWinter: 'Inverno',
    SeasonalitSpring: 'Primavera',
    SeasonalitSummer: 'Estate',
    SeasonalitAutumn: 'Autunno',
    PropensityShortTerm: 'Indebitamento a breve termine',
    PropensityMiddTerm: 'Indebitamento a medio termine',
    PropensityCarLeas: 'Leasing targato',
    PropensityInstrLeas: 'Leasing strumentale',
    PropensityRealEstateLeas: 'Leasing immobiliare',
    PropensityFactoring: 'Factoring',
    Internazionalization: 'Score internazionalizzazione',
    Social: 'Score social',
    DigitalAttitude: 'Digital attitude',
    Innovation: 'Score di innovazione',
    Supplier: 'Score fornitori',
    EstimatedTurnover: 'Fatturato stimato',
    AnalyticsMarketingTooltip: 'Analytics Marketing',
    AnalyticsFinanceTooltip: 'Analytics Finance',
    AnalyticsMarketingMobileTooltip: 'Marketing ',
    AnalyticsFinanceMobileTooltip: 'Finance',
    SeasonalitTooltip:
      "Intercetta la stagione dell'anno in cui le imprese manifestano maggior bisogno di credito a breve termine. Utile per la selezione di imprese prospect o clienti e la pianificazione di campagne in determinati periodi.",
    PropensityTooltip:
      'Determina la propensione dell’impresa all’acquisto di prodotti di credito: credito breve termine, credito medio termine, factoring, leasing (targato, strumentale, immobiliare). Lo score di propensione di ciascun prodotto viene misurato in 3 fasce (1 = Alto, 2 = Medio, 3 = Basso).',
    PropensityFactoringTooltip: 'Score di propensione - Factoring',
    InternazionalizationTooltip:
      'Indica la propensione delle imprese a lavorare con l’estero.\nAssume valori da 1 (Alta propensione) a 5 (Bassa propensione)',
    SocialTooltip:
      "Score social indica quanto un'impresa è attiva sui social, quanto è seguita e quanto spesso pubblica",
    DigitalAttitudeTooltip:
      "Misura l'affinità al canale digitale dell'impresa, la propensione all'adozione di processi digitali (es: digital onboarding) e maggiore reattività alle campagne marketing digitali.\nAssume valori da 1 (Alta digitalizzazione) a 5 (Bassa digitalizzazione)",
    InnovationTooltip:
      "Misura il livello di innovazione delle imprese, è correlato con la crescita dell'impresa.\nAssume valori da 1 (Alta innovazione) a 5 (Bassa innovazione)",
    SupplierTooltip: 'Score fornitori',
    EstimatedTurnoverTooltip:
      'Per imprese che non presentano bilancio, è stato sviluppato un modello di stima in grado di ricostruire l’ultimo fatturato.',
    TelcoFixScore: 'Score di consumo telefonico fisso',
    TelcoMobScore: 'Score di consumo telefonico mobile'
  },
  Mastercard: {
    Title: 'Retails',
    Business: 'Indicatore Business',
    BusinessTooltip:
      'Indica il giro d’affari dell’attività commerciale dell’impresa sulla base del trend degli ultimi 12 mesi. Assume valori da 1 (Alto) a 3 (Basso)',
    Competitiveness: 'Indicatore di Competitività',
    CompetitivenessTooltip:
      'Indica il potenziale dell’impresa, rispetto al contesto competitivo in cui opera. Se alto, indica che l’attività è tra le migliori all’interno del suo contesto settoriale e geografico.\nAssume valori da 1 (Alto) a 3 (Basso)',
    Digitalization: 'Indicatore di Digitalizzazione',
    DigitalizationTooltip:
      'Indica il livello di sviluppo del canale digitale nell’attività commerciale dell’impresa. Assume valori da 1 (Alto) a 3 (Basso)',
    Premium: 'Indicatore Premium',
    PremiumTooltip:
      'Indica l’incidenza di clientela alto spendente nell’attività commerciale dell’impresa rispetto ai valori medi del settore. Se è alto, significa che la spesa dei clienti nel punto vendita è maggiore della spesa media nei punti vendita simili. Assume valori da 1 (Alto) a 3 (Basso)',
    CommercialPotential: 'Indicatore di Potenzialità Commerciale',
    CommercialPotentialTooltip:
      'Indica la potenzialità commerciale dell’area geografica rispetto ai valori medi del settore. Assume valori da 1 (Alto) a 3 (Basso)',
    CompetitionLevel: 'Indicatore Livello di Competizione',
    CompetitionLevelTooltip:
      'Indica la concentrazione di esercizi commerciali dello stesso settore nell’area in cui si trova l’attività. Assume valori da 1 (Alto) a 3 (Basso)',
    Performance: 'Indicatore di Performance',
    PerformanceTooltip:
      "Indica il modo in cui il commerciante reagisce all'emergenza in termini di generazione di affari rispetto alle sue prestazioni prima del lockdown. Assume valori da 1 (Buone performance) a 5 (Basse performance).",
    Restart: 'Indicatore di Ripartenza',
    RestartTooltip:
      'Indica lo stato del commerciante in funzione della tipologia di riapertura dopo il lockdown. Sarà basso se chiuso, e alto se ha un comportamento normale con transazioni e pagamenti. Assume valori da 1 (Andamento normale) a 5 (Chiusura).'
  },
  Portfolio: {
    Title: 'Portafogli associati',
    NumberOfCompanies: 'Numero di imprese',
    CreationDate: 'Data di creazione',
    LastUpdateDate: 'Ultima data di aggiornamento ',
    AddToPortfolio: 'Aggiungi a Portafoglio',
    SaveCompany: 'Salva la impresa',
    SaveOrCreate: "Vuoi aggiungere l'impresa a un portafoglio specifico o vuoi crearne uno nuovo?",
    SaveCompanyDesc: 'La impresa verrà salvata automaticamente come prospect nella sezione "Gestione Portafoglio"'
  },
  SalesTransaction: {
    Title: 'Feedback Assegnatario',
    Name: 'Nome',
    Product: 'Prodotto',
    Assignee: 'Assegnatario',
    Task: 'Task',
    startDate: "Data d'inizio",
    EndDate: 'Data di fine attività',
    Notes: 'Note'
  },
  UnlockView: {
    Title: 'Acquista dati {{companyName}} per sbloccare questa sezione',
    MissingPortfoliosTitle: "Qui troverai tutti i portafogli in cui è presente l'impresa",
    MissingSalesTransactionsTitle: "Qui troverai tutte le campagne in cui è presente l'impresa",
    Subtitle: 'Puoi sbloccare questa sezione, acquistando dati {{companyName}}',
    Button: 'Sblocca dati {{companyName}}',
    Tooltip: 'Per favore aggiungi il tooltip...'
  },
  ModalWindow: {
    Notes: 'Note',
    Title: 'Puoi aggiungere fino a 5 note per questa impresa',
    InsertNote: '  Insert a new note (max 250 characters)',
    NewNote: 'Scrivi qui una nuova nota',
    Add: 'Aggiungi',
    AreYouSure: 'Sei sicuro di eleminare questa nota?',
    Yes: 'Sì',
    No: 'No'
  },
  CompanyProfile: 'Profilo Impresa',
  CompanyProfileMobile: 'Impresa',
  CompanySize: 'Dimensione impresa',
  CompanySizeMobile: 'Dimensione',
  FinancialStatementSummary: 'Dati di bilancio',
  FinancialStatementMobile: 'Bilancio',
  EmployeesData: 'Statistiche dipendenti',
  EmployeesDataMobile: 'Dipendenti',
  Contacts: {
    Title: 'Contatti'
  },
  News: 'News',
  Close: 'Chiudi',
  LocalUnits: {
    Title: 'Unità locali',
    StartDate: 'Data Apertura'
  },
  Score: {
    Title: 'Indicatori custom',
    Tabs: {
      Index: 'Indicatori custom',
      Relevant: 'Eventi rilevanti',
      Telco: 'Analytics Telco',
      Analytics: 'Analytics Utilities'
    }
  },
  CadastralData: {
    Title: 'Dati catastali',
    totalNumberOfOwnProperties: "Numero totale di immobili di proprietà dell'impresa",
    numberOfResidentialBuildings: "Fabbricati residenziali posseduti dall'impresa",
    numberOfCommercialBuildings: "Fabbricati commerciali posseduti dall'impresa",
    numberOfOtherBuildings: "Altri fabbricati posseduti dall'impresa",
    numberOfLands: "Terreni posseduti dall'impresa"
  },
  Pnrr: {
    Title: 'PNRR',
    Headers: {
      Score: 'PNRR Score Investment',
      Mission: 'Mission',
      Component: 'Component',
      Scope: 'Scope',
      Investment: 'Investment'
    },
    Tenders: {
      Name: 'Nome bando',
      Status: 'Stato del bando',
      StartDate: 'Data inizio bando',
      EndDate: 'Data scadenza bando',
      WebPageUrl: 'Url bando',
      WebPageUrlTitle: 'Clicca qui per il Bando'
    }
  },
  CustomIndexes: {
    Title: 'Indicatori custom',
    Tabs: {
      Index: 'Indicatori custom',
      Warnings: 'Attenzione'
    },
    NegativeEventsPresent: 'Eventi negativi presenti',
    NegativeEventsNotPresent: 'Eventi negativi non presenti',
    cbiRiskTooltip: 'Indica la probabilità di default. Varia fra 1 (Basso Rischio) e 8 (Alto Rischio).',
    commercialCreditAppraisalCribisDBTooltip:
      "Indica la massima esposizione consigliata per transizioni commerciali, sulla base delle caratteristiche dell'impresa.",
    cribisDBRatingTooltip:
      "Indica il profilo di rischio di credito dell'impresa. Varia fra 1 (Basso Rischio) e 4 (Alto Rischio).",
    cribisScorePATooltip:
      'Indice di rischio di credito specifico per le imprese della pubblica amministrazione. Varia da 1 (Alto Rischio) a 5 (Basso Rischio).',
    delinquencyScoreTooltip:
      'Valuta la probabilità di ritardo nei pagamenti. Varia fra 1 (Alto Rischio) e 100 (Basso Rischio).',
    delinquencySectorScoreTooltip:
      'Indica la probabilità di ritardo nei pagamenti in uno specifico settore. Varia da 1 (Alto Rischio) a 100 (Basso Rischio).',
    failScoreTooltip:
      "Indica l'affidabilità di un'impresa e la capacità di onorare i debiti nei successivi 12 mesi. Varia tra 1 (Alto Rischio) e 100 (Basso Rischio).",
    itpRiskTooltip: 'Descrive i giorni di ritardo (da 0 a 240) per i pagamenti verso i fornitori.',
    governanceRiskIndexGriTooltip:
      "Indica il rischio legato alla governance dell'impresa. Analizza le Operazioni Straordinarie, il Rating di Legalità, la valutazione sul Gruppo Aziendale, Esponenti e Soci, Organi di Vigilanza e Controllo, etc. Varia da 1 (Basso Rischio) a 5 (Alto Rischio).",
    paydexCribisDBTooltip:
      "Valuta le performance storiche di pagamento verso i fornitori e indica la capacità di un'impresa di rispettare i tempi di pagamento. Varia tra 1 (Alto Rischio) e 100 (Basso Rischio).",
    sectorsFailureScoreTooltip:
      "Indica il rischio di default di un'impresa. Varia tra 1 (Alto Rischio) e 100 (Basso Rischio).",
    sectorsPaydexTooltip:
      'Valuta le performance storiche di pagamento verso i fornitori e indica il rischio di ritardo nei pagamenti del settore. Varia fra 1 (Alto Rischio) e 100 (Basso Rischio).',
    supplierTooltip:
      'Indice di valutazione di un fornitore. Misura la qualità dei fornitori in termini di certificazioni ambientali, i legami con la Pubblica Amministrazione, i trend rispetto ai benchmark di mercato. Varia da 1 (Alta Qualità) a 5 (Bassa Qualità).',
    telcoPhoneConsumptionScoreTooltip:
      'Indica l’intensità di ricorso al traffico telefonico su rete fissa . Varia da 1 (Molto Alto) a 5 (Molto Basso).',
    tlcPhoneFastwebTooltip:
      'Indica l’intensità di ricorso al traffico telefonico su rete fissa . Varia da 1 (Oltre 250k €) a 5 (Fino a 1,5k €).',
    telcoMobilePhoneConsumptionScoreTooltip:
      'Indica l’intensità di ricorso al traffico telefonico su rete mobile. Varia da 1 (Molto Alto) a 5 (Molto Basso).',
    electricityConsumptionScoreTooltip:
      'Indica il livello di consumo di energia elettrica. Varia da 1 (Alto Consumo) a 5 (Basso Consumo).',
    gasConsumptionScoreTooltip: 'Indica il livello di consumo di gas. Varia da 1 (Alto Consumo) a 5 (Basso Consumo).',
    electricityScoreA2aTooltip:
      'Indica il livello di consumo di energia elettrica, misurato in Wattora (Wh). Varia da 1 (Alto Consumo) a 5 (Basso Consumo).',
    gasScoreA2aTooltip:
      'Indica il livello di consumo di gas (misurato in Standard Metro Cubo (smc). Varia da 1 (Alto Consumo) a 5 (Basso Consumo).',
    pnrrMainScores: {
      m1Tooltip:
        'Indica lo score PNRR associato alla Missione 1 . Varia da 1 (massimo) a 5 (minimo). ATTENZIONE: Se si selezionano due o più investimenti, verranno visualizzate tutte le imprese che sono maggiormente beneficiarie dei vari investimenti, sia quelle indirette, nonché le beneficiarie di un solo investimento.',
      m2Tooltip:
        'Indica lo score PNRR associato alla Missione 2 . Varia da 1 (massimo) a 5 (minimo). ATTENZIONE: Se si selezionano due o più investimenti, verranno visualizzate tutte le imprese che sono maggiormente beneficiarie dei vari investimenti, sia quelle indirette, nonché le beneficiarie di un solo investimento.',
      m3Tooltip:
        'Indica lo score PNRR associato alla Missione 3 . Varia da 1 (massimo) a 5 (minimo). ATTENZIONE: Se si selezionano due o più investimenti, verranno visualizzate tutte le imprese che sono maggiormente beneficiarie dei vari investimenti, sia quelle indirette, nonché le beneficiarie di un solo investimento.',
      m5Tooltip:
        'Indica lo score PNRR associato alla Missione 5 . Varia da 1 (massimo) a 5 (minimo). ATTENZIONE: Se si selezionano due o più investimenti, verranno visualizzate tutte le imprese che sono maggiormente beneficiarie dei vari investimenti, sia quelle indirette, nonché le beneficiarie di un solo investimento.',
      mainScoreTooltip:
        'Indica lo score sintetico per il PNRR . Varia da 1 (massimo) a 5 (minimo). ATTENZIONE: Se si selezionano due o più investimenti, verranno visualizzate tutte le imprese che sono maggiormente beneficiarie dei vari investimenti, sia quelle indirette, nonché le beneficiarie di un solo investimento.'
    }
  },
  CompanyPopup: {
    Title: 'Messaggio di sistema',
    CompanyNotFound: 'Non è stato possibile recuperare nessun dato per la company selezionata'
  },
  PersonPopup: {
    Title: 'Messaggio di sistema',
    Desc: 'Non è stato possibile recuperare nessun dato per la persona selezionata'
  },
  PdfPopup: {
    Title: 'Conferma Operazione',
    TextEnriched:
      "Appena il documento in PDF verrà creato riceverai una notifica, e potrai scaricarlo e salvarlo. Procedere con l'operazione?",
    TextNotEnriched:
      "Per scaricare il PDF è necessario arricchire l'impresa selezionata. L'operazione potrebbe richiedere alcuni minuti. Conferma per procedere."
  },
  AccessPurchasePage: 'Richiedi Report Affidamento',
  CheckSubsidizedFinancing: 'Controlla Finanza Agevolata',
  CompanySurveys: {
    FillOutCheckList: 'Compila la check-list',
    SelectTitle: 'Seleziona una check-list da compilare',
    SurveyInfo: {
      Grey: 'Le check-list in grigio, indicano che non è ancora stato compilato un questionario.',
      Green: 'Le check-list in verde, indicano che è già stato compilato un questionario.',
      Yellow: 'Le check-list gialle indicano che è presente un questionario in bozza.'
    },
    UploadFile: 'Caricare un file',
    UploadFileTooltip: 'Formati accettati: {{acceptedFormat}} Dimensione massima: {{maxSize}}MB',
    SaveAsDraft: 'Salva come bozza',
    SaveAndSend: 'Salva e invia',
    ErrorInUploadDialog: {
      Title: 'Errore nel caricamento',
      FormatFileError: 'Il formato del file non è accettato.',
      MaxSizeError: 'La dimensione del file è troppo grande.',
      NameTooLongError: `Il nome del file è troppo lungo. Caratteri consentiti: ${MAX_FILE_NAME_LENGTH}.`
    },
    SavingFailedDialog: {
      Title: 'Errore nel salvataggio',
      Text: 'Si è verificato un errore nel salvataggio. Riprova più tardi o contatta il customer service'
    },
    Confirmation: {
      Title: 'Invia check-list al CRM',
      Text1:
        'Attenzione! Cliccando sul pulsante "Salva e invia", il questionario e i suoi allegati verranno inviati al CRM.',
      Text2: "Il questionario non sarà più modificabile. Procedere con l'operazione?"
    }
  },
  Appointments: {
    Title: 'Appuntamenti'
  },
  Esg: {
    Title: 'ESG',
    UnlockDate: 'Data di sblocco ESG',
    Sector: {
      Title: 'Score Settoriale ESG'
    },
    Unlock: {
      Title: 'Sblocca i dati ESG per visualizzare questa sezione',
      Subtitle: 'Puoi sbloccare questa sezione acquistando i dati ESG',
      TitleNotAllowed: 'Siamo spiacenti, questa sezione non è al momento disponibile',
      SubtitleNotAllowed: 'I relativi campi saranno visibili una volta che un Supervisor li avrà sbloccati.',
      Button: 'Sblocca dati ESG'
    },
    Company: {
      Title: 'Score Specifico ESG',
      Environment: 'Environment',
      Social: 'Social',
      Governance: 'Governance',
      ESGScoreFactors: 'ESG Score Factors',
      ESGTransitionRisks: 'Rischio di Transizione ESG',
      ESGPhysicalRisks: 'Rischio Fisico ESG'
    },
    PhysicalRisk: {
      acuteRisks: 'Rischio Fisico ESG - Acuto',
      chronicRisks: 'Rischio Fisico ESG - Cronico',
      combinedRisks: 'Rischio Fisico ESG - Combinato',
      earthquakeRisks: 'Rischio Fisico ESG - Terremoto'
    },
    Tooltips: {
      emissions:
        "Indica l'impatto ambientale per emissioni di gas serra in una scala 1 (alto punteggio) a 3 (basso punteggio).",
      energyEfficiency:
        'Indica il livello di efficienza energetica dell’attività produttiva in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      waterUsage:
        "Indica uno score per l'utilizzo di acqua corrente in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      wasteProduction:
        'Indica uno score per la quantità prodotta di rifiuti generici o pericolosi in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      biodiversity:
        "Indica uno score per l'operatività in aree naturali in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      environmentalHazards:
        'Indica uno score di rischio fisico per calamità naturali in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      communitySociety:
        "Indica l'impatto sociale sulla comunità locale in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      employeeRelationship:
        'Indica la qualità del rapporto con i dipendenti in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      customerRelationship:
        'Indica la qualità del rapporto con i clienti in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      povertyFamine:
        "Indica l'impegno a garantire opportunità per gruppi economicamente svantaggiati in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      humanRights:
        "Indica l'impegno per rispetto dei diritti umani in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      transparency:
        'Indica uno score per la trasparenza in tema ESG sulle pratiche impresali in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      ethicalConsiderations:
        "Indica uno score per l'integrità della condotta impresale in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      strategyRiskManagement:
        'Indica uno score per la strategia sulla Gestione dei Rischi in scala da 1 (alto punteggio) a 3 (basso punteggio).',
      inclusiveness:
        "Indica uno score per l'inclusività verso donne e giovani in scala da 1 (alto punteggio) a 3 (basso punteggio).",
      revenueTransitionScore:
        'Fornisce una valutazione dell’impatto potenziale della transizione ESG sul fatturato in scala da 1 (impatto positivo) a 5 (impatto negativo).',
      investmentsTransitionScore:
        'Fornisce una valutazione dell’ammontare di investimenti aggiuntivi richiesti dalla transizione ESG in scala da 1 (aumento contenuto) a 5 (aumento significativo).',
      ebitdaTransitionScore:
        "Fornisce una valutazione dell'impatto che la transizione ESG ha sulla  marginalità in scala da 1 (impatto positivo) a 5 (impatto negativo).",
      overallTransitionScore:
        'Fornisce una valutazione sull’impatto della transizione ESG in termini economico-finanziari in scala da 1 (impatto trascurabile) a 5 (alto impatto). ',
      landslide:
        "Indica l'esposizione al pericolo di frane e subsidenze. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      coldWave:
        "Indica l'esposizione al pericolo di ondate di freddo e gelate. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      drought:
        "Indica l'esposizione al pericolo di siccità. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      heatWaves:
        "Indica l'esposizione al pericolo di ondate di calore. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      heavyPrecipitation:
        "Indica l'esposizione al pericolo di forti precipitazioni. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      wildfire:
        "Indica l'esposizione al pericolo di incendi boschivi. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      extremeWind:
        "Indica l'esposizione al pericolo di cicloni/uragani/trombe d'aria. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      flood:
        "Indica l'esposizione al pericolo di inondazioni. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      changingPrecipitation:
        "Indica l'esposizione al pericolo di cambiamenti del tipo di precipitazioni. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      changingTemperature:
        "Indica l'esposizione al pericolo di cambiamenti della temperatura. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      changingWindPatterns:
        "Indica l'esposizione al pericolo di cambiamenti della direzione dei venti. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      soilCoastalErosion:
        "Indica l'esposizione al pericolo di erosione del suolo e costiera. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      heatStress:
        "Indica l'esposizione al pericolo di stress termico. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      permafrostThawing:
        "Indica l'esposizione dell’impresa allo scongelamento del permafrost. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      seaLevelRise:
        "Indica l'esposizione al pericolo di innalzamento del livello del mare. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      soilDegradation:
        "Indica l'esposizione al pericolo di degradazione del suolo. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      waterStress:
        "Indica l'esposizione al pericolo di stress idrico. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).",
      earthquake:
        'Indica l’esposizione al pericolo di terremoto, considerando anche unità locali e settore di appartenenza. Varia da 1 (bassa rischiosità) a 10 (massima rischiosità).',
      combinedChronic:
        "Raggruppa tutti i fenomeni cronici ed esprime la pericolosità geografica e del danno atteso sull'impresa, considerando anche le unità locali e il settore di appartenenza. Varia da 1 (bassa rischiosità) a 5 (massima rischiosità).",
      combinedAcute:
        "Raggruppa tutti i fenomeni acuti ed esprime la pericolosità geografica e del danno atteso sull'impresa, considerando anche le unità locali e il settore di appartenenza. Varia da 1 (bassa rischiosità) a 5 (massima rischiosità).",
      combinedFull:
        "Raggruppa tutti i fenomeni naturali (cronici, acuti e terremoto) ed esprime la pericolosità geografica e del danno atteso sull'impresa, considerando anche le unità locali e il settore di appartenenza. Varia da 1 (bassa rischiosità) a 5 (massima rischiosità).",
      flagChronicAcute:
        "Considera l’esposizione ad almeno un rischio significativo di pericolo Cronico e Acuto. Può assumere valore 0 (nessuna esposizione) o 1 (almeno un'esposizione).",
      scoreE: 'Valutazione sintetica dell\'impresa sulla componente "Environment". Varia da 1 (alta) a 5 (bassa).',
      scoreS: 'Valutazione sintetica dell\'impresa sulla componente "Social". Varia da 1 (alta) a 5 (bassa).',
      scoreG: 'Valutazione sintetica dell\'impresa sulla componente "Governance". Varia da 1 (alta) a 5 (bassa).',
      scoreEsg: "Valutazione complessiva dell'impresa sugli indicatori ESG. Varia da 1 (alta) a 5 (bassa).",
      sector: {
        scoreE:
          'Valutazione sintetica del settore di riferimento sulla componente "Environment".Varia da 1 (alta) a 5 (bassa).',
        scoreS:
          'Valutazione sintetica del settore di riferimento sulla componente "Social". Varia da 1 (alta) a 5 (bassa).',
        scoreG:
          'Valutazione sintetica del settore di riferimento sulla componente "Governance". Varia da 1 (alta) a 5 (bassa).'
      }
    },
    Table: {
      ScoreFactor: 'Score factor',
      Description: 'Descrizione',
      Score: 'Score'
    }
  },
  UnlockCompanyEsgDataDialog: {
    Title: 'Sblocca Score ESG',
    SubTitle: 'Vuoi sbloccare lo Score ESG per questa impresa? ',
    UnlockText:
      'I dati saranno sbloccati e aggiornati per sei mesi dalla data di sblocco, o fino alla data di scadenza della sottoscrizione se antecedente. Alla data di scadenza dei dati ESG per questa impresa, sarà necessario sbloccarli nuovamente.',
    UnlockingText:
      'Sto sbloccando il Punteggio ESG per questa azienda. Questa operazione potrebbe richiedere alcuni momenti.',
    CompanyUnlockedText:
      'Il Punteggio ESG è stato sbloccato con successo per questa azienda. Ora puoi chiudere questa finestra di dialogo.',
    CompanyNotUnlockedText:
      "Lo sblocco del Punteggio ESG non è riuscito a causa dell'assenza di dati ESG per questa azienda.",
    ErrorUnlockingEsgData: 'Si è verificato un errore durante lo sblocco dei dati ESG. Per favore riprova più tardi.',
    InsufficientCredits: 'Non hai abbastanza crediti per sbloccare i dati ESG per questa azienda.'
  }
}
