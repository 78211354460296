/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Back: 'Zpět',
  Continue: 'Pokračovat',
  Search: 'Hledat',
  Cancel: 'Zrušit',
  Ok: 'Ok',
  Save: 'Uložit',
  SaveAndShare: 'Uložit a sdílet',
  Delete: 'Vymazat',
  Move: 'Přesunout',
  Edit: 'Upravit',
  Rename: 'Přejmenovat',
  Add: 'Přidat',
  Note: 'Poznámka',
  Select: 'Vybrat',
  Hide: 'Skrýt',
  Show: 'Zobrazit',
  Visualise: 'Vizualizovat',
  HideDetail: 'Skrýt detail',
  ShowDetail: 'Zobrazit detail',
  Duplicate: 'Duplikovat',
  Download: 'Stáhnout',
  Collapse: 'Redukovat',
  Loading: 'Načíta se',
  Language: 'Jazyk',
  // COMPANY STATUS
  Prospect: 'Prospekt',
  Lead: 'Lead',
  Client: 'Klient',
  Supplier: 'Dodavatel',
  Competitor: 'Konkurent',
  RangeSelected: 'Vybraná oblast',
  Close: 'Zavřít',
  ViewDetails: 'Zobrazit podrobnosti',
  SearchResults: {
    ResultsFor: 'Výsledky pro',
    ExcludeHiddenPortfolio: ', s výjimkou společností patřících do skrytých portfolií.',
    SendToSemanticSearch: 'Odeslat do sémantického vyhledávání',
    SendToSemanticSearchToolTip: 'Vyhledejte klíčové slovo v sémantickém vyhledávání'
  },
  NumericRange: {
    InvalidValue: 'Neplatná hodnota.',
    MinGreaterThanMax: 'Min. hodnota je větší než max.',
    MaxLessThanMin: 'Max. hodnota je menší než min.',
    MinOutOfrange: 'Minimální hodnota je mimo rozsah.',
    MaxOutOfrange: 'Maximální hodnota je mimo rozsah. '
  },
  DateRange: {
    From: 'Od',
    To: 'do'
  },
  DomainFilter: {
    SaveAndClose: 'Uložit filtry a zavřít',
    SeeAll: 'Všechny {{count}} filtry',
    DeleteAll: 'Vymazat vše',
    Multiselection: 'Multi-výběr'
  },
  Reset: 'Resetovat',
  SelectAll: 'Vybrat vše',
  DeselectAll: 'Odznačit vše',
  DoYouWantToContinue: 'Chcete pokračovat?',
  NotSwitch: 'Not',
  Yes: 'Ano',
  No: 'Ne',
  On: 'Zap',
  Off: 'Vyp',
  LoadMore: 'Načíst více',
  SeeAll: 'Vidět vše',
  HideAll: 'Schovat',
  Filters: {
    UnlockFilters: 'Chcete upřesnit vyhledávání? <0>Kliknutím sem</0> odemkněte více než 300 Margo filtrů',
    NoFilters: 'Nevybrali jste žádné filtry!',
    Select: 'Vybrat filtry',
    Filters: 'Filtry',
    EditFilters: 'Upravit filtry k aktualizaci seznamu',
    StartNewSearch: 'Začít nové vyhledávání',
    ResetFilters: 'Resetuj všechny filtry',
    UploadList: 'Nahraj seznam',
    UploadListTooltip: 'Vložte jednu nebo více hodnot oddělených mezerou.'
  },
  MapFilter: {
    FindMe: 'Najdi mě na mapě',
    Address: 'Adresa',
    Range: 'Rozsah',
    SelectedCircle: '{{distance}} od {{address}}',
    SelectedPolygon: 'Oblast vybraná na mapě',
    CompanyName: 'Název společnosti'
  },
  InsertTheNumber: 'Vložte číslo',
  OrderBy: 'Seřadit podle',
  BackToLogin: 'Zpět k přihlášení',
  SimpleUsefulPowerful: 'Jednoduché, užitečné a výkonné.',
  MargoWasSurprise: 'Margo bylo pro mě a můj tým úžasným překvapením.',
  Targeting: 'Analýza trhu',
  Prospecting: 'Výběr klientů',
  PortfolioManagement: 'Správa portfolia',
  Actions: 'Akce',
  GroupActions: {
    GroupActions: 'Skupinová akce',
    Actions: 'Akce',
    Select: 'Výběr',
    ToggleTooltip:
      'Přepnutím skupinových akcí vyberte požadované společnosti zaškrtnutím nebo proveďte vícenásobný výběr. Vybrané společnosti pak můžete uložit z nabídky Akce. Nebudete moci vybrat žádnou výzvu k akci.',
    ButtonDisabledByGroupActions:
      'Aktivací skupinových akcí nebudete moci vybrat žádnou výzvu k akci. Chcete-li tato tlačítka používat, vypněte přepínač Skupinové akce.',
    DeactivateToggleTooltip:
      'Deaktivujte skupinové akce, aby ste mohli používat tlačítka výzvy k akci v horní části stránky.'
  },
  Confirm: 'Potvrdit',
  IsEnriched: 'Obohacená',
  Logout: 'Odhlásit',
  ChangeCountry: 'Změnit zemi',
  TrialUser: 'Zkušební účet',
  You: 'Vy',
  Legend: 'Legenda',
  Enrich: {
    Enrichment: 'Obohacení',
    RecapDesc:
      'Obohacením společnosti o údaje společnosti {{companyName}} se tyto informace stáhnou a budou se pravidelně aktualizovat.',
    CompaniesToBeEnriched_0: 'Společnost k obohacení',
    CompaniesToBeEnriched_1: 'Společnosti k obohacení',
    CompaniesToBeEnriched_2: 'Společnosti k obohacení',
    PortfolioInfo1: 'Tato společnost není v sekci Správa portfolia. Přidejte si ji do portfolia.',
    PortfolioInfo2: 'Ukládáte údaje společnosti {{companyName}}, společnost už bude obohacena.',
    SelectPortfolio: 'Vybrat portfolio',
    UploadedPortfolio: 'Nahrané portfolio',
    CreateNewPortfolio: 'Vytvořit nové portfolio',
    PortfolioName: 'Název portfolia',
    EnrichmentComplete: 'Obohacení bylo dokončeno'
  },
  ConfigGuardErrorPage: {
    Reload: 'Znovu načíst',
    Subtitle: 'Aplikačný config se nepodařilo načíst.',
    Title: 'Je nám líto'
  },
  Uploaded: 'Nahraných',
  Date: 'Datum',
  Page: 'Strana',
  Validating: 'Validace',
  AutoLogout: {
    Title: 'Automatické odhlášení',
    Text_0: 'Z důvodu vaší neaktivity budete automaticky odhlášen za {{count}} sekundu',
    Text_1: 'Z důvodu vaší neaktivity budete automaticky odhlášen za {{count}} sekundy',
    Text_2: 'Z důvodu vaší neaktivity budete automaticky odhlášen za {{count}} sekund'
  },
  ForceLogout: {
    Title: 'Uživatel odhlášen',
    Desc: 'Váš uživatel byl odhlášen, protože aktuální relace byla ukončena nebo byla iniciována nová relace prostřednictvím jiného prohlížeče nebo zařízení.'
  },
  PrivacyPolicy: 'Zásady ochrany osobních údajů',
  CookiesPolicy: 'Pravidla používání cookies',
  TermsOfUse: 'Podmínky použití',
  SelectDate: 'Vybrat datum',
  queries: {
    loadQuery: {
      dialogTitle: 'Načíst uložené vyhledávání',
      OkBtn: 'Načíst',
      title: 'Vybrat z uložených vyhledávání ',
      description: 'Vyberte, ve které analýze chcete pokračovat. Můžete pokračovat jednou ze svých uložených analýz.',
      selectTitle: 'Uložený dotaz'
    }
  },
  SaveList: {
    CreateTagTitle: 'Vytvořit značku',
    CreateTagLabel: 'Vytvořit značku (volitelné)',
    CreateTagDesc: 'Každá společnost bude definována značkou, kterou vytvoříte',
    InsertTag: 'Vložit značku',
    SaveList: 'Uložit značku',
    SaveOrCreate: 'Chcete přidat seznam do portfolia nebo vytvořit nové portfolio?',
    SelectPortfolio: 'Vybrat portfolio',
    SaveListDesc: 'Seznam se automaticky uloží se statusem "Prospekt" v části "Správa portfolia"',
    UploadedPortfolio: 'Aktualizované portfolio',
    CreateNew: 'Vytvořit nové',
    SelectNumberOfCompanies:
      'Vyberte počet společností, které chcete přidat do svého portfolia, a seřaďte je podle jednoho z kritérií v seznamu.',
    NumberOfCompanies: 'Počet společností',
    BackToFullList: 'Zpět do kompletního seznamu',
    SavingFullList: 'Ukládáte kompletní seznam společností',
    OrderByOptions: {
      Turnover: 'Obrat',
      NOfEmployees: 'Počet zaměstnanců',
      TurnoverEur: 'Obrat (EUR)',
      TurnoverCzk: 'Obrat (CZK)',
      EmployeeRange: 'Počet zaměstnanců',
      Ebit: 'Ebit'
    },
    SendToSalesInfo: 'Před zasláním seznamu do obchodního nástoje musíte vytvořit portfolio ze seznamu.',
    Enrichment: {
      Enrichment: 'Obohacení',
      EnrichmentComplete: 'Obohacení bylo dokončeno',
      EnrichmentDescription:
        'Prostřednictvím obohacení společnosti o {{companyName}} data, budou tyto informace staženy a pravidelně aktualizovány',
      CompaniesInList_0: 'Společnost na seznamu',
      CompaniesInList_1: 'Společnosti na seznamu',
      CompaniesInList_2: 'Společností na seznamu',
      CompaniesEnriched_0: 'Obohacená společnost',
      CompaniesEnriched_1: 'Obohacené společnosti',
      CompaniesEnriched_2: 'Obohacených společností',
      CompaniesNotEnriched_0: 'Neobohacená společnost',
      CompaniesNotEnriched_1: 'Neobohacené společnosti',
      CompaniesNotEnriched_2: 'Neobohacených společností',
      CreditsWillBeUsed_0: 'Použitá jednotka',
      CreditsWillBeUsed_1: 'Použité jednotky',
      CreditsWillBeUsed_2: 'Použitých jednotek',
      CreditsRemaining_0: 'Zbývající jednotka',
      CreditsRemaining_1: 'Zbývající jednotky',
      CreditsRemaining_2: 'Zbývajících jednotek'
    },
    SaveListMonitorDesc:
      'V případě monitorovaného portfolia naleznete dotaz uložený v části "Výběr klientů" ve vašem účtu.',
    Tag: 'Značka',
    AllowedCompaniesCountExceeded: 'Seznam nemůže obsahovat více než 100 tisíc společností',
    DontShowThisMessageAnymore: 'Tuto zprávu již nezobrazovat'
  },
  ExcludePortfolio: {
    BasedOn: 'Na základě',
    SelectedPortfolios: 'Vybraná portfolia',
    AllCompanyInCrifIta: 'Všech italských společnostech',
    AllCompanyInCrifDeu: 'Všech německých společnostech',
    AllCompanyInCrifCzsk: 'všech dostupných společnostech',
    ExcludePortfolio: 'Vyloučit portfolio',
    ExcludeCampaign: 'Vyloučit kampaň',
    ExcludeEnrichedCompanies: 'Vyloučit obohacené společnosti',
    ExcludeHiddenPortfolio: 's výjimkou společností vykazovaných ve skrytých portfoliích',
    Configuration: 'konfigurace nastavené na vašem účtu',
    AndConfiguration: 'a konfigurace nastavené na vašem účtu'
  },
  User: {
    Role: {
      Supervisor: 'Supervisor',
      AreaManager: 'Area manažer',
      Sales: 'Obchodník'
    },
    UserName: 'Uživatelské jméno'
  },
  Freemium: {
    NotAvailableFeature: 'Není k dispozici pro Margo Freemium',
    UnlockFeature: '<0>Kliknutím sem</0> kontaktujte náš prodejní tým a odemknete všechny funkce',
    UnlockSection: 'Chcete-li tuto sekci odemknout, kontaktujte náš zákaznický servis',
    MaximumNumberOfPortfoliosReached:
      'Dosáhli jste maximálního počtu portfolií. <0>Kliknutím sem</0> odemknete další portfolia spolu se všemi ostatními funkcemi kontaktováním našeho prodejního týmu nebo smažte jedno portfolio ze stávajících.'
  },
  FeatureNotEnabled: 'Tuto funkci aktuálně nemáte povolenou. Chcete-li jej povolit, kontaktujte nás.',
  ClickToSort: 'Klikni pro řazení',
  Assignee: 'Příjemce',
  SaveQuery: {
    DialogTitle: 'Uložit vyhledávání',
    Description:
      'Zadejte název, který chcete použít k uložení výzkumu, nebo vyberte existující dotaz, který chcete přepsat.',
    InputLabel: 'Název dotazu',
    InputPlaceholder: 'Název vyhledávání',
    UpdateExisting: 'Aktualizovat stávající',
    CreateNew: 'Vytvořit nový',
    DoNotCreate: 'Nevytvářet',
    AddToDashboardConfirmMessage: 'Chcete přidat dotaz na svůj dashboardu?',
    AddToDashboardCheckboxText: 'Zobrazit na dashboardu',
    SavedQuery: 'správně uložený.',
    SavedQueryAndDashboard: 'uložený a přidaný do dashboardu.',
    SavedQueryDescription: 'Dotaz najdete uložený v úložišti souvisejícím s referenční oblastí.'
  },
  AssistantAi: {
    ChatTitle: 'Asistent Margò',
    Assistant: 'Asistent',
    Customer: 'Ty',
    Writing: 'Píšící',
    Feedback: 'Děkuji za vaši zpětnou vazbu!',
    Hello: 'Ahoj {{userFirstName}}, jak vám mohu pomoci?',
    Placeholder: 'Vložte zde vaši zprávu',
    DeletionConfirmation: 'Jste si jistý, že chcete smazat chat?'
  },
  ShareQuery: {
    Offer: {
      Title: 'Sdílet dotaz',
      Subtitle: 'Vyberte uživatele',
      Description: 'Vyberte uživatele, se kterými chcete sdílet dotaz',
      ShareSuccess: 'Aktualizace sdílení proběhla správně',
      ShareError: 'Sdílení dotazu selhalo'
    },
    Confirmation: {
      DialogTitle: 'Uložit sdílený dotaz',
      SelectName: 'Vyberte název pro dotaz nebo ponechte původní.',
      SharingAcceptedSubtitle: 'Výzkum {{queryName}} byl úspěšně přijat a uložen',
      SharingAcceptedText: 'Výzkum najdete uložený v úložišti souvisejícím s referenčním modulem.',
      Accept: 'Uložit sdílený dotaz'
    }
  }
}
