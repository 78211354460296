import styled, { css } from 'styled-components/macro'
import { Select as AntSelect, TreeSelect as AntTreeSelect } from 'antd'

export const StyledOption = styled(AntSelect.Option)`
  text-overflow: ellipsis;
`
export const commonSelectStyle = () => css`
  &.ant-select {
    width: 100%;
    display: flex;
  }

  &.ant-select .ant-select-selector {
    display: flex;
    flex-grow: 1;
    min-height: 40px;
    border: none;
    background-color: ${({ theme }) => theme.colors.paleGrey};
    padding-right: 40px;
    overflow: hidden;

    .ant-select-selection-overflow {
      width: 80%;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      font-size: 14px;
      line-height: 1.71;
      color: ${({ theme }) => theme.colors.brownGray};
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      display: flex !important;
      align-items: center;
    }

    // override display flex to hide default title when search text is filled
    .ant-select-selection-item[style*='display: none'] {
      display: none !important;
    }

    .anticon-loading svg {
      fill: ${({ theme }) => theme.colors.main};
    }

    .ant-select-disabled .ant-select-selection-item {
      background-color: ${({ theme }) => theme.colors.main};
      color: ${({ theme }) => theme.colors.white};
      cursor: not-allowed;
    }
  }

  &.ant-select.ant-select-disabled {
    .ant-select-selector {
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }
  }

  &.ant-select.ant-select-single {
    min-height: 40px;
    .ant-select-selection-search-input {
      min-height: 40px;
    }
  }

  &.ant-select.ant-select-multiple {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.main};
    }

    .ant-select-selector:after {
      display: none;
    }

    .ant-select-selection-item-remove {
      line-height: 22px;
      display: flex;
      align-items: center;

      svg {
        width: 13px;
        height: 13px;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

export const commonDownArrowStyle = () => css`
  position: absolute;
  top: 0;
  right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
  transition: transform ${({ theme }) => theme.animation.duration} ease;
`

export const arrowOpenAnimation = () => css`
  transform: rotate(180deg);
`

export const StyledSelect = styled(AntSelect)<{ $background?: string }>`
  ${commonSelectStyle}

  &.ant-select + .down-arrow {
    ${commonDownArrowStyle}
  }

  &.ant-select-open + .down-arrow {
    ${arrowOpenAnimation}
  }

  .ant-select-selector {
    ${({ $background }) =>
      $background &&
      `
    background: ${$background} !important;
  `}
  }

  .ant-select-selection-item {
    span {
      color: ${({ theme }) => theme.colors.white};
      font-weight: normal;
    }
  }
`

export const StyledTreeSelect = styled(AntTreeSelect)`
  ${commonSelectStyle}

  &.ant-select > .ant-select-arrow {
    margin-top: 0px;
    ${commonDownArrowStyle}
  }

  &.ant-select-open > .ant-select-arrow {
    ${arrowOpenAnimation}
  }
`
