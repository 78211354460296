/* eslint-disable import/no-default-export */
export default {
  Wizard: {
    TypeOfAnalysis: 'Art der Analyse',
    StartAnalysis: 'Um die Analyse zu starten, müssen Sie den Analysetyp auswählen, den Sie verarbeiten möchten',
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Öffnen Sie eine gespeicherte Abfrage',
    MarketAnalysisBtn: 'Starten Sie eine neue Marktanalyse',
    MarketAnalysisBtnTooltip:
      'Visualisieren und segmentieren Sie eine Reihe von Unternehmen nach den Hauptvariablen und erstellen Sie Ihr Ziel',
    FromTargeting: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    FromTargetingTooltip: 'Öffnen Sie eine im Modul "Analyze Market" gespeicherte Abfrage',
    FromProspecting: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    FromProspectingTooltip: 'Öffnen Sie eine im Prospektionsmodul gespeicherte Abfrage',
    PortfolioQuery: 'Beginnen Sie mit einer Portfolio Management Suche',
    TargetingQuery: 'Beginnen Sie mit einer gespeicherten Marktanalyse',
    ProspectingQuery: 'Beginnen Sie mit einer gespeicherten prospect Suche',
    ChooseQuery: 'Wählen Sie die Suche aus, die Sie laden möchten'
  },
  ChartLabels: {
    Company: 'Unternehmen',
    Company_plural: 'Unternehmen',
    ChartDetail: 'Graf Details',
    SaveGraph: 'Grafik speichern',
    ChangeChart: 'Diagramm ändern',
    DeleteChart: 'Diagramm löschen',
    PercentageOfCompanies: '% von Unternehmen',
    NumberOfCompanies: 'Anzahl an Unternehmen',
    NumberOfEmployees: 'Anzahl an Mitarbeitern',
    Amount: 'Menge',
    Risk: 'Risikolevel',
    Employees: 'Mitarbeiter',
    Revenue: 'Umsatz',
    RevenueCz: 'Umsatz',
    RevenueSk: 'Umsatz',
    Other: 'Andere',
    Years: 'Jahre',
    Status: 'Status',
    HiddenNDNotice: 'Bemerkung: Unternehmensgruppen ohne Daten werden nicht angezeigt',
    HiddenChartSentence:
      'Hinweis: In dieser Tabelle werden nur die ersten 20 relevantesten Kategorien mit zugehörigen Werten angezeigt. Laden Sie die Excel-Datei herunter, um alle Kategorien und die zugehörigen Werte in Prozent anzuzeigen.',
    EmptyChart: {
      Title: 'Wählen Sie den Diagrammtyp und die Variable aus',
      SelectVariableDescription:
        'Wählen Sie die Variable aus, die Sie analysieren möchten, und wählen Sie dann den Diagrammtyp aus',
      SelectVariableLabel: 'Variable auswählen',
      SelectChartLabel: 'Diagrammtyp auswählen',
      CompanyInformationTitle: 'Unternehmensinformationen',
      CompanyInformationSubTitle:
        'Hier können Sie zwischen Variablen wählen, die sich auf Unternehmensdetails beziehen',
      CustomIndexTitle: 'Benutzerdefinierter Index',
      CustomIndexSubTitle:
        'Hier können Sie zwischen Variablen wählen, die sich auf den benutzerdefinierten Index beziehen',
      CustomIndexNotEnabledTooltip: 'Sie haben keinen benutzerdefinierten Index in diesem Abonnement aktiviert',
      DisabledCategoryTooltip: 'Diese Kategorie enthält keine Optionen',
      UpdateChart: 'Diagramm aktualisieren',
      CreateChart: 'Diagramm erstellen',
      ChartTypes: {
        AreaChartMultiChoice: 'Flächendiagramm mit Mehrfachauswahl',
        BarChart: 'Balkendiagramm',
        HistogramChart: 'Histogramm',
        GroupedBarChart: 'Gruppiertes Balkendiagramm',
        PieChart: 'Kreisdiagramm',
        TreeMap: 'Baumkarte',
        Map: 'Karte'
      }
    }
  },
  Header: {
    Targeting: 'Analyze Market',
    Title: 'Analyze Market',
    LoadQuery: 'Gespeicherte Suche laden',
    LoadQueryTooltip: 'Öffnen Sie eine gespeicherte Abfrage',
    SaveQuery: 'Suche Speichern',
    SaveQueryTooltip: 'Speichern Sie diese Abfrage im Modul "Analyze Market"',
    Download: 'PDF/Excel herunterladen',
    DownloadTooltip: 'Laden Sie einen Bericht zu dieser Abfrage auf Ihr Gerät herunter',
    LoadPortfolio: 'Portfolio Laden',
    LoadPortfolioTooltip: 'Öffnen und analysieren Sie ein Portfolio',
    SendToProspecting: 'Anfrage an Prospecting senden',
    SendToProspectingTooltip: 'Zeigen Sie die Liste der Unternehmen an, die zu dieser Abfrage gehören',
    SendToProspectingFromPortfolioTooltip: 'Zeigen Sie potenziellen Unternehmen an, die den Suchkriterien entsprechen',
    SendToProspectingMessage: 'Bitte ein Filter auf die Analyse anwenden, um diese Schlatfläche frei zu schalten',
    SaveList: 'als Portfolio Speichern',
    SaveListTooltip: 'Speichern Sie diese Unternehmensliste in einem Portfolio',
    SendToOtherModules: 'An andere Module senden',
    SendToOtherModulesTooltip: 'Senden Sie Unternehmen an das Portfolio Management oder Prospecting Modul'
  },
  Filters: {
    CompanyCount: 'Unternehmen',
    ActiveCompanyCount: 'Aktiv',
    OtherCompanyCount: 'Andere',
    StatusSelectLabel: 'Status auswählen',
    StatusSelectTooltip:
      'Es wurde der erste verfügbare Status Firmenwert ausgewählt. Um den Status Company zu ändern, wählen Sie einen Wert aus der Dropdown-Liste unten aus.',
    ShowBySelectLabel: 'Anzeigen nach',
    AggregationFieldAmount: 'Menge',
    AggregationFieldNumCompanies: 'Anzahl an Unternehmen',
    DisabledFilterTooltip: 'Die Analysen auf dieser Seite beziehen sich ausschließlich auf die Hauptzentrale.'
  },
  Search: {
    SemanticSearch: 'Semantische Suche',
    SearchPlaceholder: 'Suche nach Stichwörtern',
    All: 'Alle',
    OfficialData: 'Offizielle Daten',
    Website: 'Web & Social',
    Synonyms: 'Synonyme',
    Advanced: 'Advanced semantic search',
    AdvancedSwitch: 'Fortschrittlich',
    AdvancedTooltip:
      'Bei der advanced semantic search können Sie mehrere Wörter verwenden, die durch logische Operatoren (AND, OR, NOT) verknüpft sind. Um dies zu aktivieren, stellen Sie den Schalter auf ON. Für weitere Informationen können sie die Dokumentation herunter laden.',
    DownloadSsGuide: 'Anleitung zur semantischen Suche herunterladen'
  },
  DomainFilter: {
    SaveAndClose: 'Filter speichern & schließen'
  },
  LoadQuery: {
    IntroSectionTitleProspecting: 'Wählen Sie die Prospecting Suche aus, die Sie laden möchten',
    IntroSectionTitleTargeting: 'Wählen Sie die Marktanalyse Suche aus, die Sie laden möchten',
    IntroSectionDescription: 'Sie können eine bereits gestartete Suche fortsetzen.',
    DialogTitle: 'Gespeicherte Suche laden',
    DialogSubTitle: 'Gespeicherte Suche auswählen',
    DialogDescription: 'Wählen Sie eine gespeicherte Analyse zum Fortsetzen aus.',
    SelectLabel: 'Gespeicherte Suche',
    Load: 'Laden',
    LoadingMessage: 'Analyse laden',
    InvalidPortfolioTitle: 'Ungültiges Portfolio',
    InvalidPortfolioMessage:
      'Die gespeicherte Anfrage ist nicht mehr verfügbar. Die weitere Analyse basiert auf der {{companyName}} Datenbank.',
    TargetingRadio: 'Marktanalyse Suche',
    ProspectingRadio: 'Prospect Suche'
  },
  LoadPortfolio: {
    DialogTitle: 'Portfolio laden',
    DialogSubTitle: 'Portfolio auswählen',
    Description: 'Wählen Sie ein Portfolio für die Analyse.',
    SelectLabel: 'Portfolio gespeichert',
    Load: 'Laden',
    LoadingMessage: 'Portfolio läd'
  },
  SaveBeforeContinuing: {
    Question: 'Möchten Sie die Suche speichern bevor Sie die Suche laden?',
    Description: 'Um keien Daten zu verlieren, speichern Sie die Suche bevor Sie eine neue Anfrage starten.',
    OkBtn: 'Suche speichern',
    CancelBtn: 'Speichern überspringen'
  },
  MarketAnalysisWizard: {
    StepOne: {
      Header: 'Wählen Sie das Portfolio aus, das Sie analysieren möchten ',
      Description:
        'Sie können die Analyse aus einem oder mehreren Ihrer Portfolios verarbeiten oder die aggregierte Analyse aller Unternehmen im {{companyName}} anzeigen.',
      PortfolioOption: 'Portfolio auswählen',
      AggregateOption: 'Alle Unternehmen im {{companyName}}-System',
      UploadedPortfolio: 'Hochgeladenes Portfolio'
    },
    StepTwo: {
      Header: 'Wählen Sie den zu analysierenden Status',
      DescriptionLine1: 'Wählen Sie den Ausgangsstatus der zu analysierenden Unternehmen.',
      DescriptionLine2: 'Während der Analyse können Sie den Status mithilfe von Filtern ändern.',
      Status: 'Status'
    }
  },
  Download: {
    ModalTitle: 'PDF/Excel herunterladen',
    SelectType: 'Format wählen',
    SelectTypeDescription:
      'Sie können den Kompletten Reprt zur Analyse in 2 Formen herunterladen; wählen Sie ein Format.',
    DownloadAnalysis: 'Analyse herunterladen',
    Pdf: 'PDF',
    PdfDescription: 'Im PDF-Format herunterladen',
    PdfRecap: 'Sie laden die Analyse als PDF Datei herunter.',
    Excel: 'Excel',
    ExcelDescription: 'Im XLSX-Format herunterladen',
    ExcelRecapLine1: 'Sie laden die Analyse als Liste herunter.',
    ExcelRecapLine2: 'Es wird in Ihrem Account unter "Analyze Market" gespeichert.',
    CompanyInPortfolio: 'Unternehmen in diesem Portfolio',
    CompanyInAnalysis: 'Analysiertes Unternehmen',
    CompanyInAnalysis_plural: 'Analysierte Unternehmen',
    DownloadPdf: 'PDF herunterladen',
    DownloadPdfPleaseWait: 'Bitte warten, der PDF Report wird erstellt',
    DownloadAllChartsMustBeLoaded:
      'Bitte warten Sie, bis alle Charts und Grafiken geladen sind, um den Bericht heruterzuladen',
    DownloadPdfDisabledIE:
      'Das Herunterladen des PDF Berichts wir von dieser Browser Version nicht unterstütz. Bitte aktualisieren Sie dem Browser.',
    DownloadPdfTypeChoose: 'Sowohl Daten als auch Grafiken herunterladen?',
    PdfPage: {
      Title: 'Bericht analysieren',
      CompanyPortfolioStatus: 'Unternehmensstatus im Portfolio',
      DataShownBy: 'Daten anzeigen nach',
      ExcludedPortfolios: 'Ausgeschlossene Portfolien'
    }
  },
  UnavailableForMobile: 'Die Seite ist nur für Tables und Desktops zugänglich.',
  ChartFetchError: 'Während des Ladens der Grafik ist ein Fehler aufgetreten.',
  ChartFetchRetry: 'Nochmal',
  ChartEmpty: 'Keine Daten zur Darstellung vorhanden',
  ChartErrorCompanyLimit: 'Wählen Sie mindestens {{minNumberOfCompanies}} Unternehmen aus, um die Grafik anzuzeigen.',
  BasedOnHeadquarter: 'Die angegebenen Zahlen beziehen sich auf den Verwaltungssitz',
  VisualizeAs: {
    List: 'Als Liste visualisieren',
    Map: 'Auf Karte visualisieren'
  },
  WarningRespectingLocalUnits: {
    Title: 'Anreicherung',
    Text: 'Firmen mit dem Bürotyp "Verwaltungssitz", "Verwaltungssitz und Sitz" und "Sitz" wurden angereichert (falls ausgewählt). Um Unternehmen mit dem Office-Typ "Lokale Einheit" zu bereichern, aktivieren Sie bitte das Addon Lokale Einheiten, indem Sie sich an den Kundenservice wenden.'
  },
  SendToOtherModulesDialog: {
    Title: 'Modul auswählen',
    SubTitle: 'Wählen Sie das Modul aus, an das Sie Ihre Analyse oder Liste senden möchten',
    PortfolioManagement: 'Portfolio management',
    PortfolioManagementDescription:
      'Klicken Sie hier, um unter Beibehaltung der bei der Analyse verwendeten Suchkriterien zum Portfolio zurückzukehren und die Liste der ermittelten Unternehmen einzusehen.',
    Prospecting: 'Prospecting',
    ProspectingDescription:
      'Klicken Sie hier, um die Liste der in der Marktforschung identifizierten Unternehmen an das Prospecting-Modul zu senden, um die Liste der Unternehmen anzuzeigen, ihre Berichte einzusehen und möglicherweise Ihre Suche durch die Auswahl anderer Kriterien und Filter zu verfeinern.',
    SelectPortfolio: 'Portfolio auswählen',
    OkButton: 'Senden'
  }
}
