import React, { useMemo } from 'react'
import { Text } from '@components/Text'

import { TEXT_FIELDS_DEBOUNCE_WAIT } from '@features/filters/constants'
import { useDebounce } from '@hooks/useDebounce'
import { useGetSuggestions } from '@api/suggestions/queries'
import { Size, Spin } from '@components/Loading'
import _ from 'lodash'
import { CommonSuggestionResponseProperties, CompanySuggestionsResponse, HighlightBounds } from '@api/suggestions/types'
import styled, { css } from 'styled-components/macro'
import { AutoComplete } from 'antd'
import Search from '@assets/icons/search.svg?react'
import { MAX_SEARCH_LENGTH, MIN_SEARCH_LENGTH } from '@pages/Search/constants'

export type UseGetSuggestedOptionsConfig = {
  debounceTime?: number
}

export const DEFAULT_SUGGESTION_SIZE = 6

export const wrapperMixin = css`
  overflow: hidden;
  margin-right: 12px;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.white};
`

export const ResultWrapper = styled.div`
  ${wrapperMixin}
  width: '100%';
  max-width: '100%';
  display: flex;
`

const SearchWrapper = styled(ResultWrapper)`
  align-items: center;
  justify-content: space-between;
`

const IconWrapper = styled.span`
  path {
    fill: ${({ theme }) => theme.colors.main};
  }
  svg {
    width: 16px;
    height: 16px;
  }
  width: 16px;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
`

const SearchComponent = ({ value }: { value: string | undefined }) => {
  return (
    <SearchWrapper>
      <Text>{value}</Text>
      <IconWrapper>
        <Search />
      </IconWrapper>
    </SearchWrapper>
  )
}

export const shouldDisplayCurrentSearchAndSuggestions = (value: string | undefined) =>
  value !== undefined && value.length >= MIN_SEARCH_LENGTH && value.length <= MAX_SEARCH_LENGTH

const getCurrentSearchOption = (value: string | undefined) => {
  // Currently search option if more than 3 characters
  return shouldDisplayCurrentSearchAndSuggestions(value) ? (
    <AutoComplete.Option key="AS" value={value?.trimEnd()}>
      <SearchComponent value={value?.trimEnd()} />
    </AutoComplete.Option>
  ) : undefined
}

export const areBoundsValid = (bounds: HighlightBounds[] | undefined, text: string | undefined) => {
  if (!bounds || bounds.length === 0 || !text) {
    return false
  }

  let isValid = true
  _.forEach(bounds, bound => {
    if (
      !_.isNumber(bound.from) ||
      !_.isNumber(bound.to) ||
      bound.from < 0 ||
      bound.to > text.length ||
      bound.from > bound.to
    ) {
      isValid = false
      return false
    }
    return true
  })
  return isValid
}

export const getSuggestionWithEmphasis = (suggestion: CommonSuggestionResponseProperties) => {
  const { text, bounds } = suggestion
  if (!areBoundsValid(bounds, text)) {
    return [{ text, hasEmphasis: false }]
  }

  const labelParts: { text: string; hasEmphasis: boolean }[] = []
  bounds!
    .sort((a, b) => a.from - b.from)
    .forEach((bound, index) => {
      // not emphasised parts
      if (index === 0) {
        if (bound.from !== 0) {
          labelParts.push({ text: text.slice(0, bound.from), hasEmphasis: false })
        }
      } else if (bounds![index - 1].to !== bound.from) {
        labelParts.push({ text: text.slice(bounds![index - 1].to, bound.from), hasEmphasis: false })
      }

      // actual highlight
      labelParts.push({ text: text.slice(bound.from, bound.to), hasEmphasis: true })

      if (index === bounds!.length - 1 && bound.to !== text.length) {
        // not emphasised after last bounds
        labelParts.push({ text: text.slice(bound.to, text.length), hasEmphasis: false })
      }
    })

  return labelParts
}

export const SuggestionComponent = ({ suggestion }: { suggestion: CommonSuggestionResponseProperties }) => {
  const suggestionText = getSuggestionWithEmphasis(suggestion).map((labelPart, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Text key={index} bold={labelPart.hasEmphasis} whiteSpace="pre" ellipsis>
      {`\u2060${labelPart.text}\u2060`}
    </Text>
  ))

  return <ResultWrapper>{suggestionText}</ResultWrapper>
}

export const SpinWrapper = styled.span`
  margin: 24px;
`
const useGetSuggestedOptions = (value: string | undefined, { debounceTime }: UseGetSuggestedOptionsConfig = {}) => {
  const debouncedValue = useDebounce(value, debounceTime ?? TEXT_FIELDS_DEBOUNCE_WAIT)

  const { data: suggestions, isFetching } = useGetSuggestions<CompanySuggestionsResponse>(
    { query: debouncedValue!, size: DEFAULT_SUGGESTION_SIZE, suggestionTypes: ['Company'] },
    { enabled: shouldDisplayCurrentSearchAndSuggestions(debouncedValue) }
  )

  let suggestedOptions: JSX.Element[] | undefined

  if (isFetching) {
    suggestedOptions = [
      <AutoComplete.Option key="SP" value={value}>
        <SpinWrapper>
          <Spin key="spinner" size={Size.Small} />
        </SpinWrapper>
      </AutoComplete.Option>
    ]
  } else {
    suggestedOptions = suggestions
      ? suggestions?.results.map((suggestion, index) => {
          const prevSuggestions = suggestions?.results.slice(0, index)
          const prevSuggestionsCount = _.countBy(prevSuggestions, s => s.text)[suggestion.text] || 0
          // add extra space for current suggestion
          const whiteSpaceCount = prevSuggestionsCount + 1
          // the key and value are used for the backfill feature
          // however it must be unique so append a whitespace to each non unique suggestion result
          const uniqueWhitespace = _.repeat(' ', whiteSpaceCount)
          return (
            <AutoComplete.Option
              key={`${suggestion.companyUnitId}/${suggestion.identificationCode}`}
              value={suggestion.text + uniqueWhitespace}
            >
              <SuggestionComponent suggestion={suggestion} />
            </AutoComplete.Option>
          )
        })
      : undefined
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ({ suggestedOptions, isFetching }), [isFetching, suggestions])
}

export const useGetOptions = (value: string | undefined, config: UseGetSuggestedOptionsConfig = {}) => {
  const currentSearchOption = useMemo(() => getCurrentSearchOption(value), [value])
  const { suggestedOptions, isFetching } = useGetSuggestedOptions(value, config)

  const additionalOptions = useMemo(() => {
    return shouldDisplayCurrentSearchAndSuggestions(value) && suggestedOptions ? suggestedOptions : []
  }, [value, suggestedOptions])

  const options = useMemo(
    () => _.compact([isFetching ? null : currentSearchOption, ...additionalOptions]),
    [additionalOptions, currentSearchOption, isFetching]
  )

  return {
    options
  }
}
