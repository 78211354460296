import { AutoComplete as AntdAutoComplete } from 'antd'
import styled from 'styled-components/macro'
import { inputStyles } from '@components/Input'

export const AutoComplete = styled(AntdAutoComplete)`
  .ant-select.ant-select-auto-complete:hover {
    border-color: ${({ theme }) => theme.colors.paleGrey};
  }

  .ant-select-selector.ant-select-selector {
    ${inputStyles()}
    border-color: ${({ theme }) => theme.colors.paleGrey} !important;
    :focus,
    :focus-within {
      border: 1px solid ${({ theme }) => theme.colors.main} !important;
      box-shadow: none !important;
    }
  }

  &.ant-select-status-error {
    .ant-select-selector.ant-select-selector {
      ${inputStyles()}
      border-color: ${({ theme }) => theme.colors.antdError} !important;
      :focus,
      :focus-within {
        border: 1px solid ${({ theme }) => theme.colors.antdError} !important;
        box-shadow: none !important;
      }
    }
  }

  &.ant-select.ant-select-disabled {
    .ant-select-selector {
      background-color: ${({ theme }) => theme.colors.paleGrey};
    }
  }
`
