/* eslint-disable import/no-default-export */
/* eslint-disable camelcase */
export default {
  Back: 'Zurück',
  Continue: 'Weiter',
  Search: 'Suchen',
  Cancel: 'Abbrechen',
  Ok: 'Ok',
  Save: 'Speichern',
  SaveAndShare: 'Speichern und teilen',
  Delete: 'Löschen',
  Move: 'Verschieben',
  Edit: 'Bearbeiten',
  Rename: 'Umbenennen',
  Add: 'Hinzufügen',
  Note: 'Notiz',
  Select: 'Wählen',
  Hide: 'Verbergen',
  Show: 'Anzeigen',
  Visualise: 'Visualisieren',
  HideDetail: 'Detail ausblenden',
  ShowDetail: 'Detail anzeigen',
  Duplicate: 'Dublette',
  Download: 'Herunterladen',
  Loading: 'Wird geladen',
  Language: 'Sprache',
  // COMPANY STATUS
  Prospect: 'Prospect',
  Lead: 'Lead',
  Client: 'Kunden',
  Supplier: 'Lieferanten',
  Competitor: 'Wettbewerber',
  RangeSelected: 'Bereich ausgewählt',
  Close: 'Schließen',
  ViewDetails: 'Details anzeigen',
  SearchResults: {
    ResultsFor: 'Ergebnisse für',
    ExcludeHiddenPortfolio: ', ohne Unternehmen, die zu verdeckten Portfolios gehören.',
    SendToSemanticSearch: 'An Semantische Suche senden',
    SendToSemanticSearchToolTip: 'Suchen Sie nach dem Schlüsselwort in der semantischen Suche'
  },
  NumericRange: {
    DataNotAvailable: 'Daten für die im Fokus stehenden Unternehmen nicht verfügbar.',
    InvalidValue: 'Wert ist ungültig.',
    IntegersOnly: 'Es sind nur Ganzzahlen zulässig.',
    MinGreaterThanMax: 'Der Minimalwert ist größer als der Maximalwert.',
    MaxLessThanMin: 'Der Maximalwert ist niedriger als der Minimalwert.',
    MinOutOfrange: 'Der Mindestwert liegt außerhalb des Bereichs.',
    MaxOutOfrange: 'Maximalwert liegt außerhalb des Bereichs.'
  },
  DateRange: {
    From: 'Ab',
    To: 'Bis'
  },
  DomainFilter: {
    SaveAndClose: 'Filter speichern & schließen',
    SeeAll: 'Alle {{count}} Filter',
    DeleteAll: 'Alles löschen',
    Multiselection: 'Multiselektion'
  },
  Reset: 'Reset',
  SelectAll: 'Alle auswählen',
  DeselectAll: 'Alle nicht auswählen',
  DoYouWantToContinue: 'Möchten Sie fortsetzen?',
  NotSwitch: 'Not',
  Yes: 'Ja',
  No: 'Nein',
  DoNotKnow: 'Weiß nicht',
  On: 'An',
  Off: 'Aus',
  LoadMore: 'Weitere laden',
  SeeAll: 'Alles anzeigen',
  HideAll: 'Versteck alles',
  Filters: {
    UnlockFilters:
      'Möchten Sie Ihre Suche verfeinern? Schalten Sie Margos über 300 Filter frei, indem <0>Sie hier klicken</0>',
    NoFilters: 'Sie haben keine Filter ausgewählt!',
    Select: 'Filter auswählen',
    Filters: 'Filter',
    EditFilters: 'Filter bearbeiten, um die Liste zu aktualisieren',
    ResetFilters: 'alle Filter zurücksetzen',
    StartNewSearch: 'Starten Sie eine neue Suche'
  },
  MapFilter: {
    FindMe: 'Finde mich auf der Karte',
    Address: 'Adresse',
    Range: 'Umkreis',
    SelectedCircle: '{{distance}} von {{address}}',
    SelectedPolygon: 'Auf der Karte ausgewählter Bereich',
    CompanyName: 'Firmenname'
  },
  InsertTheNumber: 'Geben Sie die Nummer ein',
  OrderBy: 'Sortiert nach',
  BackToLogin: 'Zurück zur Anmeldung',
  SimpleUsefulPowerful: 'Einfach, nützlich und leistungsstark.',
  MargoWasSurprise: 'margò war eine wundervolle Bereicherung für mein Team und mich.',
  Targeting: 'Marktanalyse',
  Prospecting: 'Prospect suchen',
  PortfolioManagement: 'Portfolio Management',
  Actions: 'Aktionen',
  GroupActions: {
    GroupActions: 'Gruppenaktionen',
    Actions: 'Aktion',
    Select: 'Wählen',
    ToggleTooltip:
      'Schalten Sie Gruppenaktionen um, um die gewünschten Unternehmen auszuwählen, indem Sie das Häkchen setzen, oder treffen Sie eine Mehrfachauswahl. Anschließend können Sie die ausgewählten Unternehmen über das Menü Aktionen speichern. Sie können keinen Call-to-Action auswählen.',
    ButtonDisabledByGroupActions:
      'Wenn Sie Gruppenaktionen aktivieren, können Sie keinen Call-to-Action auswählen. Um diese Schaltflächen zu verwenden, schalten Sie den Schalter Gruppenaktionen aus.',
    DeactivateToggleTooltip:
      'Deaktivieren Sie Gruppenaktionen, um die Call-to-Action-Schaltflächen oben auf der Seite verwenden zu können.'
  },
  Confirm: 'Bestätigen',
  IsEnriched: 'Angereichert',
  Logout: 'Ausloggen',
  ChangeCountry: 'Land ändern',
  TrialUser: 'Testbenutzer',
  Legend: 'Legende',
  ConfigGuardErrorPage: {
    Reload: 'Neu laden',
    Subtitle: 'Die Anwendungskonfiguration konnte nicht geladen werden.',
    Title: 'Wir entschuldigen uns für die Unannehmlichkeiten'
  },
  Uploaded: 'Hochgeladen',
  Date: 'Datum',
  Page: 'Seite',
  Validating: 'Validieren',
  Enrich: {
    Enrichment: 'Anreicherung',
    RecapDesc:
      'Durch Anreicherung mit {{companyName}} Daten werden diese Informationen heruntergeladen und periodisch aktualisiert',
    CompaniesToBeEnriched: 'Firma werden angereichert',
    CompaniesToBeEnriched_plural: 'Firmen werden angereichert',
    PortfolioInfo1:
      'Das Unternehmen ist noch nicht im Portfolio Management enthalten. Fügen Sie diese bitte dem Portfolio hinzu.',
    PortfolioInfo2: 'Durch Bestätigung werden die Daten angereichert',
    SelectPortfolio: 'Portfolio auswählen',
    UploadedPortfolio: 'Portfolio hochgeladen',
    CreateNewPortfolio: 'Neues Portfolio erstellen',
    PortfolioName: 'Portfolioname',
    EnrichmentComplete: 'Anreicherung komplett'
  },
  AutoLogout: {
    Title: 'Automatische Abmeldung',
    Text: 'Aufgrund Ihrer Inaktivität werden Sie automatisch in {{count}} Sekunden abgemeldet'
  },
  ForceLogout: {
    Title: 'Abgemeldet',
    Desc: 'Ihr Benutzer wurde abgemeldet, weil die Sitzung beendet oder eine neue Sitzung über einen anderen Browser oder ein anderes Gerät initiiert wurde.'
  },
  ImprintPolicy: 'Impressum',
  PrivacyPolicy: 'Datenschutzbestimmungen',
  CookiesPolicy: 'Cookie-Richtlinie',
  TermsOfUse: 'Nutzungsbedingungen',
  SelectDate: 'Datum wählen',
  queries: {
    loadQuery: {
      dialogTitle: 'Gespeicherte Suche laden',
      OkBtn: 'Laden',
      title: 'Gespeicherte Suche auswählen ',
      description: 'Wählen Sie eine gespeicherte Analyse zum Fortsetzen aus.',
      selectTitle: 'Laden'
    }
  },
  SaveList: {
    CreateTagTitle: 'Tag erstellen',
    CreateTagLabel: 'Tag erstellen (optional)',
    CreateTagDesc: 'Jede Firma wird durch ein von Ihnen erstelltes Tag definiert',
    InsertTag: 'Tag einfügen',
    SaveList: 'Liste speichern',
    SaveOrCreate: 'Möchten Sie die Liste einem vorhandenen Portfolio hinzufügen oder möchten Sie eine neue erstellen?',
    SelectPortfolio: 'Portfolio auswählen',
    SaveListDesc: 'Die Liste wird automatisch mit dem Status "Prospect" im Bereich "Portfolio Management" gespeichert',
    UploadedPortfolio: 'Aktualisiere Portfolio',
    CreateNew: 'neu erstellen',
    SelectNumberOfCompanies:
      'Wählen Sie die Anzahl der Unternehmen aus, die Sie Ihrem Portfolio hinzufügen möchten, und sortieren Sie sie nach einem der Kriterien in der Liste.',
    NumberOfCompanies: 'Anzahl Unternehmen',
    BackToFullList: 'Zurück zur vollständigen Liste',
    SavingFullList: 'Sie speichern die vollständige Firmenliste',
    OrderByOptions: {
      Turnover: 'Umsatz',
      NOfEmployees: 'Anzahl Mitarbeiter',
      Ebit: 'EBIT'
    },
    SendToSalesInfo: 'Bevor Sie eine Liste an das Sales Tool senden, müssen Sie aus der Liste ein Portfolio erstellen.',
    Enrichment: {
      Enrichment: 'Anreicherung',
      EnrichmentComplete: 'Anreicherung abgeschlossen',
      EnrichmentDescription:
        'Durch die Anreicherung des Unternehmens mit {{companyName}}-Daten werden diese Informationen heruntergeladen und regelmäßig aktualisiert',
      CompaniesInList: 'Firmen in dieser Liste',
      CompaniesEnriched: 'Unternehmen bereits angereichert',
      CompaniesNotEnriched: 'Unternehmen nicht angereichert',
      CreditsWillBeUsed: 'Benutzte rings',
      CreditsRemaining: 'Verbleibende rings'
    },
    SaveListMonitorDesc:
      'Im Falle eines überwachten Portfolios finden Sie die in Ihrem Konto gespeicherte Tracking-Abfrage',
    AllowedCompaniesCountExceeded: 'Eine Liste darf nicht mehr als 100.000 Unternehmen enthalten'
  },
  ExcludePortfolio: {
    BasedOn: 'Bezogen auf',
    SelectedPortfolios: 'Ausgewählte Portfolios',
    AllCompanyInCrifIta: 'Alle italienischen Firmen',
    AllCompanyInCrifDeu: 'allen verfügbaren Unternehmen',
    AllCompanyInCrifCzsk: 'tschechischen und slowakischen Firmen',
    AllCompanyInCrifChe: 'Alle Schweizer Firmen',
    AllCompanyInCrifAut: 'Alle österreichischen firmen',
    AllCompanyInCrifCheAut: 'Alle österreichischen und schweizer firmen',
    ExcludePortfolio: 'Portfolio ausschließen',
    ExcludeCampaign: 'Kampagne ausschließen',
    ExcludeEnrichedCompanies: 'Bereicherte Unternehmen ausschließen',
    SelectCompaniesWithContacts: 'Wählen Sie Unternehmen mit Kontakten aus',
    ExcludeHiddenPortfolio: 'mit Ausnahme der in verborgenen Portfolios gemeldeten',
    HiddenPortfolioBasedOn: 'Basierend auf',
    Configuration: 'die für Ihr Konto festgelegte Konfiguration',
    AndConfiguration: 'und die für Ihr Konto festgelegte Konfiguration'
  },
  User: {
    Role: {
      Supervisor: 'Supervisor',
      AreaManager: 'Area Manager',
      Sales: 'Sales'
    },
    UserName: 'User Name'
  },
  Freemium: {
    NotAvailableFeature: 'Nicht verfügbar für Freemium Margo',
    UnlockFeature:
      '<0>Klicken Sie hier</0>, um alle Funktionen freizuschalten, indem Sie unser Vertriebsteam kontaktieren',
    UnlockSection: 'Kontaktieren Sie unseren Kundenservice, um Margo zu kaufen und diesen Abschnitt freizuschalten',
    MaximumNumberOfPortfoliosReached:
      'Sie haben die maximale Anzahl von Portfolios erreicht. Bitte <0>klicken Sie hier</0>, um weitere Portfolios und alle anderen Funktionen freizuschalten, indem Sie unser Vertriebsteam kontaktieren, oder löschen Sie eines der bestehenden Portfolios.'
  },
  FeatureNotEnabled: 'Sie haben diese Funktion derzeit nicht aktiviert. Kontaktieren Sie uns, um es zu aktivieren.',
  ClickToSort: 'Klicken zur Sortierung',
  Assignee: 'Bevollmächtigter',
  SaveQuery: {
    DialogTitle: 'Suche speichern',
    Description:
      'Geben Sie den Namen ein, unter dem Sie Ihre Suche speichern möchten, oder wählen Sie eine vorhandene Abfrage aus, um sie zu überschreiben.',
    InputLabel: 'Name der Abfrage',
    InputPlaceholder: 'Name der Suche',
    UpdateExisting: 'Update verfügbar',
    CreateNew: 'Erstelle neu',
    DoNotCreate: 'Nicht erstellen',
    AddToDashboardConfirmMessage: 'Möchten Sie die Suche zu Ihrem Dashboard hinzufügen?',
    AddToDashboardCheckboxText: 'Im Dashboard anzeigen',
    MaximumWidgetCountReached: 'Maximale Anzahl an Boxen wurde erreicht.',
    SavedQuery: 'wurde korrekt gespeichert.',
    SavedQueryAndDashboard: 'im Dashboard gespeichert und ergänzt.',
    SavedQueryDescription: 'Sie finden die Suche gespeichert im Repository bezogen auf das Referenzgebiet.'
  },
  AssistantAi: {
    ChatTitle: 'Assistent Margò',
    Assistant: 'Assistent',
    Customer: 'Du',
    Writing: 'Schreibend',
    Feedback: 'Danke für Ihr Feedback!',
    Hello: 'Hallo {{userFirstName}}, wie kann ich Ihnen helfen?',
    Placeholder: 'Fügen Sie hier Ihre Nachricht ein',
    DeletionConfirmation: 'Sind Sie sicher, dass Sie den Chat löschen möchten?'
  },
  ShareQuery: {
    Offer: {
      Title: 'Abfrage teilen',
      Subtitle: 'Wählen Sie die Benutzer aus',
      Description: 'Wählen Sie die Benutzer aus, mit denen Sie die Abfrage teilen möchten',
      ShareSuccess: 'Aktualisierung der Freigabe erfolgreich durchgeführt',
      ShareError: 'Abfragefreigabe fehlgeschlagen'
    },
    Confirmation: {
      DialogTitle: 'Gemeinsame Suche speichern',
      SelectName: 'Wählen Sie einen Namen für Ihre Suche oder behalten Sie den ursprünglichen Namen bei.',
      SharingAcceptedSubtitle: 'Die Suche <QueryName> wurde erfolgreich angenommen und gespeichert',
      SharingAcceptedText: 'Sie finden die im Repository zum Referenzmodul gespeicherten Suchen.',
      Accept: 'Gemeinsame Suche speichern'
    }
  }
}
