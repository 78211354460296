/* eslint-disable camelcase */
/* eslint-disable import/no-default-export */
export default {
  Title: 'Geplanter Termin',
  CreateAppointmentButton: 'Neuen Termin anlegen',
  ManageAppointmentsButton: 'Termin verwalten',
  EditAppointment: 'Termin bearbeiten',
  SendEmailLabel: 'Details an meinen Kalender senden und Empfänger benachrichtigen',
  ForHeadquarterButton: 'Für die Verwaltungssitz',
  ForLocalBranchButton: 'Für diese örtliche Einheit',
  AppointmentDetails: {
    Title: 'Geben Sie Namen, Datum und Ort Ihres neuen Termins ein',
    EditTitle: 'Bearbeiten Sie Namen, Datum und Ort Ihres Termins',
    MatchingTermIta: 'Steuerkennzeichen/Umsatzsteuerkennzeichen',
    MatchingTermDeu: 'Steuerkennzeichen/Umsatzsteuerkennzeichen/ONR Nummer',
    MatchingTermChe: 'Unternehmens-Identifikationsnummer / Crif ID',
    MatchingTermAut: 'MWST-Nr. / Company book number',
    MatchingTermCheAut: 'Unternehmens-Identifikationsnummer / Crif ID / MWST-Nr. / Company book number',
    MatchingTermCzsk: 'Steuerkennzeichen/Umsatzsteuerkennzeichen',
    MatchingTermFareast: 'Steuerkennzeichen/Umsatzsteuerkennzeichen',
    Name: 'Terminname',
    Description: 'Terminbeschreibung',
    Place: 'Ort',
    StartDate: 'Startdatum',
    EndDate: 'Enddatum',
    SelectColor: 'Wählen Sie eine Farbe aus'
  },
  CreateAppointment: {
    Title: 'Termin anlegen',
    SearchItem: 'Suchelement',
    ShareAppointmentStep: {
      Title: 'Empfänger auswählen',
      Text: 'Wählen Sie die Benutzer aus, an die Sie die Erinnerung weiterleiten möchten, oder geben Sie die E-Mail-Adressen der Empfänger durch Kommas getrennt ein',
      Email: 'E-Mail-Adressen, durch Kommas getrennt'
    },
    ConfirmationStep: {
      Title: 'Vorgang bestätigen',
      Text: 'Möchten Sie diesen Termin an Ihren Kalender senden? Wählen Sie EIN: Margò sendet Ihnen eine E-Mail mit allen soeben hinzugefügten Informationen'
    }
  },
  DeleteAppointment: {
    Title: 'Termin löschen',
    SubTitle: 'Möchten Sie den Termin wirklich löschen?'
  },
  AppointmentsList: {
    EmptyText: 'Hier finden Sie alle in Ihrem Kalender gespeicherten Termine.',
    NameColumn: 'Name',
    StartColumn: 'Anfang',
    EndColumn: 'Ende',
    ShowExpired: 'Abgelaufene',
    All: 'Alle'
  },
  Colors: {
    Green: 'Grün',
    Yellow: 'Gelb',
    Orange: 'Orange',
    Red: 'Rot',
    Violet: 'Violett',
    Blue: 'Blau',
    LightBlue: 'Hellblau',
    Turquoise: 'Türkis',
    Pink: 'Rosa',
    Black: 'Schwarz'
  }
}
